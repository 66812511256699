import React, { useEffect } from 'react';
import { FaFacebook, FaInstagram, FaYoutube, FaDownload, FaWhatsapp } from 'react-icons/fa';
import './ThankYouPage.css';
import pdf from '../images/generativeAI.pdf';

import { FaLinkedinIn } from 'react-icons/fa6';
import { useLocation } from 'react-router-dom';
import visa from '../images/visa-icon.svg'
import credit from '../images/master-card-icon.svg'
import payment from '../images/payment-method-icon.svg'
import razorpay from '../images/Razor-pay-icon.svg'
import upi from '../images/upi-icon.svg'
import rupay from '../images/rupay-icon.svg'
import techuLogo from "../images/techu-footer-logo.png";

import fb from '../images/facebook-icon.svg'
import insta from '../images/instgram-icon.svg'

import youtube from '../images/youtube-icon.svg'
import linkedin from '../images/linkedin-icon.png'
import email from '../images/email.icon.svg'


const ThankYouPage = () => {
    const location = useLocation()

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = pdf;
        link.download = 'generativeAI.pdf';
        link.click();
    };

    const trackConversion = () => {
        if (window.gtag) {
            console.log("gtag")
            window.gtag('event', 'conversion', {
                send_to: 'AW-11395333265/7j6mCObt4sUZEJH527kq'
            });
        }
    };

    useEffect(() => {
        if (location.pathname == '/thankyu')
            trackConversion()
    }, [location])


    return (
        <>
        <div className="container">
            <div className="content">
                <h1 className="title">Thank You!</h1>
                <p className="description">
                    We appreciate your interest. Stay connected with us on social media, download our curriculum, or visit our website for more information.
                </p>
                <p className="additional-info">
                    Someone from our team will be in touch with you shortly.
                </p>
                <div className="buttons">
                    <button className="download-button" onClick={handleDownload}>
                        <FaDownload className="button-icon" /> Download Curriculum
                    </button>
                    <a className="whatsapp-link" href="https://chat.whatsapp.com/E2hTrlJI7N9HgkYb6ROjlj" target="_blank" rel="noopener noreferrer">
                        <button className="whatsapp-button">
                            <FaWhatsapp className="button-icon whatsapp-icon" /> Join WhatsApp Channel
                        </button>
                    </a>
                </div>
                <div className="social-media">
                    <a className="social-link" href="https://www.facebook.com/techubyabilio" target="_blank" rel="noopener noreferrer">
                        <FaFacebook className="social-icon facebook-icon" />
                    </a>
                    <a className="social-link" href="https://www.instagram.com/techu_by_abilio/" target="_blank" rel="noopener noreferrer">
                        <FaInstagram className="social-icon instagram-icon" />
                    </a>
                    <a className="social-link" href="https://www.youtube.com/@Tech_U820/featured" target="_blank" rel="noopener noreferrer">
                        <FaYoutube className="social-icon youtube-icon" />
                    </a>
                    <a className="social-link" href="https://www.linkedin.com/in/techu-by-abilio-a105b731b/" target="_blank" rel="noopener noreferrer">
                        <FaLinkedinIn className="social-icon linkedin-icon" />
                    </a>
                </div>
            </div>
        </div>
        <div className='footer-main'>
        <div id="footer" data-sectionid="sections" class="footer-main">
                    <div>
                        <div class="footer-container">
                            <div class="footer-container-row-1">
                                <div class="footer-container-information-1"><a href="#"
                                    class="footer-container-logo tbtn inline-div">
                                    <div class="TechU-logo">
                                        <img
                                            src={techuLogo}
                                            alt="TechU Image" width="70%" loading="lazy" />
                                    </div>
                                </a>
                                    <div class="footer-container-col-1 contact-us email">
                                        <h6 class="heading-two">Reach Us</h6>
                                        <div class="block-div-2"><a
                                            href="tel:+919154747782" target="_blank"
                                            class="wa-btn tbtn inline-div">
                                            <div class="wa-icon">
                                                < FaWhatsapp style={{ color: "#ffffff", background: "linear-gradient(#23b33a, #23b33a, #23b33a)", borderRadius: "50%", width: "30px", height: "30px", padding: "0px" }} />
                                            </div>
                                            <div>
                                                <div class="wa-link">+91 9154747782<br />(WhatsApp only)</div>
                                            </div>
                                        </a><a href="mailto:techutraining@gmail.com"
                                            class="em-btn tbtn inline-div">
                                                <div class="em-icon ">
                                                    <img src={email} />
                                                </div>
                                                <div>
                                                    <div class="wa-link">techutraining@gmail.com</div>
                                                </div>
                                            </a></div>
                                    </div>
                                </div>

                                <div class="footer-container-row-2">
                                    <div class="block-div-3">
                                        <h6 class="heading-two navigate-links">Quick Links</h6>
                                        <div class="block-div-4">
                                            <div class="footer-container-col-1">
                                                <ul role="list" class="link-div-1 links no-style-list">
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Home<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Academy<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Hire with us<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Contact Us<br /></a></li>
                                                    <li class="link-menu-items-22"><a href="#"
                                                        class="link-item-footer tbtn">Blog<br /></a></li>
                                                </ul>
                                            </div>
                                            <div class="block-div-17">
                                                <ul role="list" class="link-div-1 links _two no-style-list">
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">About Us<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Reviews <br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Community<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">4.0 Champions<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">TechU Review</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer-container-col-1 contact-us email">
                                        <h6 class="heading-two">Payment Methods</h6>
                                        <div class="block-div-6">
                                            <div class="payment-methods-images"><img
                                                src={visa}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={credit}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={payment}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={razorpay}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={upi}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={rupay}
                                                alt='payment method' loading='lazy' /></div>
                                        </div>
                                        <div class="block-div-7">
                                            <div>
                                                <div class="location-div"></div>
                                            </div>
                                            <div>
                                                <div class="location-text">TechU, #303B Images Capital Park, Madhapur, Hyderabad - 500081</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="block-div-8"></div>

                            <div class="block-div-9">
                                <div class="social-div">

                                    <a id="footer-facebook-profile-link" rel="nofollow"
                                    href="https://www.facebook.com/techubyabilio" target="_blank"
                                    class="link-block-45 tbtn inline-div">

                                    <div class="social-icons ">
                                        <img
                                            src={fb}
                                            alt='socal media' loading='lazy' />
                                            </div>
                                </a>

                                    <a id="footer-instagram-profile-link" rel="nofollow" href="https://www.instagram.com/techu_by_abilio/"
                                        target="_blank" class="link-block-45 tbtn inline-div">
                                        <div class="social-icons "><img
                                            src={insta}
                                            alt='socal media' loading='lazy' /></div>
                                    </a>
                                    <a id="footer-youtube-profile-link" rel="nofollow" href="https://www.youtube.com/@Tech_U820/featured"
                                        target="_blank" class="link-block-43 tbtn inline-div">
                                        <div class="social-icons "><img
                                            src={youtube}
                                            alt='socal media' loading='lazy' /></div>
                                    </a>

                                    <a id="footer-youtube-profile-link" rel="nofollow" href="https://www.linkedin.com/in/techu-by-abilio-a105b731b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                        target="_blank" class="link-block-43 tbtn inline-div">
                                        <div class="social-icons "><img
                                            src={linkedin}
                                            
                                            alt='socal media' loading='lazy' width={"20px"}/></div>
                                    </a>
                                    
                                    </div>
                                <div class="block-div-10">
                                    <div class="block-div-11">
                                        <div class="privacy-item-link"><a href="#"
                                            class="link-items  tbtn">Privacy Policy</a><br /></div>
                                        <div class="cookie-item-link "><a href="#"
                                            class="link-items  tbtn">Cookie Policy</a><br /></div>
                                        <div class="terms-item-link "><a href="#" class="link-items  tbtn">Terms and Conditions</a><br /></div>
                                        <div class="grievance-item-link"><a href="#"
                                            class="link-items  tbtn">Grievance Redressal</a><br /></div>
                                        <div class="grievance-item-link-2"><a href="#" class="link-items  tbtn">Corporate Information</a><br /></div>
                                        <div id="footer-vision-and-value-statement" class="grievance-item-link-1"><a
                                            href="#"
                                            class="link-items  tbtn">Vision and Values</a><br /></div>
                                    </div>
                                </div>
                            </div>

                            <div class="block-div-8"></div>

                            <div id="ct" className='ct-main'>
                                <h6 class="heading-two cts new">Course Tracks</h6>
                                <div class="block-div-12 ">
                                    <div class="block-div-13 "><a id="footer-mern-stack-developer-ct"
                                        href="#"
                                        class="footer-navigate-link-2  heading-size  tbtn">MERN Stack Developer course</a></div>
                                    <div class="block-div-13 "><a id="footer-full-stack-developer-ct"
                                        href="#"
                                        class="footer-navigate-link-2 heading-size tbtn">Full Stack Developer course</a>
                                        <div class="block-div-14 ">
                                            <div class="full-stack-dev-c"><a href="#"
                                                class="footer-navigate-link-2  para-size  tbtn">Hyderabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bangalore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Pune</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mumbai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Delhi</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Ahmedabad</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Coimbatore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chennai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chandigarh</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Noida</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kolkata</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kochi  </a>
                                                <div class="tb-1  ds-hide mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhubaneswar</a>
                                                <div class="tb-1 ">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Visakhapatnam</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Vijayawada</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Gurgaon</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Jaipur  </a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Indore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kanpur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Nagpur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Madurai</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhopal</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trivandrum</a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Guwahati</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Patna  </a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Aurangabad </a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trichy  </a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Salem</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Thane</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kerala</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mysore</a>
                                                <div class="tb-1  ds-hide mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Telugu</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Tamil</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hindi</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="block-div-13 "><a href="#" class="footer-navigate-link-2  heading-size  tbtn">Data
                                        Analytics course</a>
                                        <div class="block-div-15">
                                            <div class="data-analytics-c"><a href="#"
                                                class="footer-navigate-link-2  para-size  tbtn">Hyderabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bangalore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Pune</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mumbai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Delhi</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chennai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kolkata</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#" class="footer-navigate-link-2  para-size  tbtn">Chandigarh</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Ahmedabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Coimbatore</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Gurgaon  </a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Noida</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kerala</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Nagpur</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Indore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Jaipur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Lucknow</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Thane</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhopal</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Dehradun</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhubaneswar</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mysore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Vadodara</a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kanpur</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trivandrum</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Surat</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Nashik  </a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Madurai   </a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Patna</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Aurangabad</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Ghaziabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Meerut</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Guwahati</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kolhapur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kochi</a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trichy</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Telugu</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Tamil</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hindi</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="block-div-13 "><a href="#" class="footer-navigate-link-2  heading-size  tbtn">QA /
                                        Automation Testing course</a>
                                        <div class="block-div-14 ">
                                            <div class="block-div-16"><a href="#"
                                                class="footer-navigate-link-2  para-size  tbtn">Bangalore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hyderabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chennai</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Pune</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Coimbatore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Noida</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Telugu</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Tamil</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hindi</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
      
        </>
        
    );
};

export default ThankYouPage;
