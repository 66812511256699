import React from 'react'
import '../components/TechuFooter.css'
import visa from '../images/visa-icon.svg'
import credit from '../images/master-card-icon.svg'
import payment from '../images/payment-method-icon.svg'
import razorpay from '../images/Razor-pay-icon.svg'
import upi from '../images/upi-icon.svg'
import rupay from '../images/rupay-icon.svg'
import techuLogo from "../images/techu-footer-logo.png";

import fb from '../images/facebook-icon.svg'
import insta from '../images/instgram-icon.svg'
import { FaWhatsapp } from 'react-icons/fa6';

import youtube from '../images/youtube-icon.svg'
import linkedin from '../images/linkedin-icon.png'
import email from '../images/email.icon.svg'



const Tech = () => {
    return (
        <div className=''>
            <div className=''>
                <section class="readmore">
                    <article className="readmore-main-container">
                        <h2 className="heading-one">Read more about Full Stack Development</h2>
                        <div className="readmores-container">
                            <div className="readmore-question-answer-container">
                                <div className="question-icon">
                                    <h4 className="readmore-heading">What is a full stack developer course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">A full-stack developer course, such as those offered by TechU, is a comprehensive educational program that trains students in both front-end and back-end web development. TechU's curriculum covers essential skills like designing user interfaces, implementing responsive web designs, developing server-side logic, and integrating databases. The courses emphasize hands-on learning with various programming languages, frameworks, and tools relevant to modern web development practices.</p>
                                    <p className="readmore-answer">Upon completing a full-stack developer course at TechU, students gain a deep understanding of web application architecture and are equipped to handle the entire development lifecycle proficiently. This practical approach ensures graduates not only possess technical expertise but also problem-solving skills and teamwork capabilities crucial in the industry. TechU's focus on practical application makes their graduates highly competitive in the job market, sought after for their ability to create robust, scalable web applications from start to finish.</p>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div className="question-icon">
                                    <h4 className="readmore-heading">Who should take a full-stack developer course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">A full-stack developer course, especially those offered by TechU, is suitable for a wide range of individuals interested in web development. Whether you're a beginner aiming to enter the tech industry, a student seeking to specialize, or a professional looking to broaden your skill set, TechU's courses cater to all levels of experience. These courses are designed to comprehensively cover front-end and back-end development, making them ideal for anyone who wants to grasp the complete process of building web applications.</p>
                                    <p className='readmore-answer'>Even with no prior coding knowledge, TechU ensures accessibility by starting from foundational principles and gradually advancing to more complex concepts. This approach enables learners to build a solid understanding of programming languages, frameworks, and database management essential for modern web development. By the end of the course, graduates from TechU are well-prepared to tackle diverse challenges in web development and stand out in the competitive job market.</p>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div className="question-icon">
                                    <h4 className="readmore-heading">Do I need any prior knowledge before taking a full-stack developer course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">Enrolling in a best full-stack developer course at TechU doesn't necessarily require prior knowledge, although having some understanding of programming and web technologies can be helpful. TechU courses are structured to cater to beginners, starting with foundational concepts and progressing to advanced topics. This approach ensures that regardless of your starting point, you'll gradually develop the skills needed to excel as a full-stack developer. Whether you're a complete novice or have some familiarity with coding, TechU's courses are designed to support your learning journey effectively.</p>

                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">What topics are covered in a full-stack developer course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">Key topics covered in a full-stack developer course at TechU typically include: </p>
                                    <ol role="list" className="list-item">
                                        <li >
                                            <p className="readmore-answer">Front-End Development: HTML, CSS, JavaScript, and advanced frameworks such as React or Angular.</p>
                                        </li>
                                        <li >
                                            <p className="readmore-answer">Back-End Development: Server-side languages like Node.js, along with frameworks like Express.</p>
                                        </li>
                                        <li >
                                            <p className="readmore-answer">Databases: Both SQL databases like MySQL and NoSQL databases such as MongoDB.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Version Control: Essential tools like Git and collaborative platforms like GitHub.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Deployment: Techniques for hosting, utilizing cloud services, and best practices in deployment.</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">What kind of job can I get after completing a full-stack developer course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">TGraduates can pursue various roles such as:</p>
                                    <ol role="list" className="list-item">
                                        <li >
                                            <p className="readmore-answer">Full-Stack Developer</p>
                                        </li>
                                        <li >
                                            <p className="readmore-answer">Front-End Developer</p>
                                        </li>
                                        <li >
                                            <p className="readmore-answer">Back-End Developer</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Web Developer</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Software Engineer</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">Why become a Full Stack Developer?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">These are some of the advantages you can consider to become Full Stack
                                        Developer -</p>
                                    <ol role="list" className="list-item">
                                        <li >
                                            <p className="readmore-answer">High demand for skilled professionals in the industry</p>
                                        </li>
                                        <li >
                                            <p className="readmore-answer">Competitive salary and attractive job opportunities</p>
                                        </li>
                                        <li >
                                            <p className="readmore-answer">Flexibility to work in various aspects of web development</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Ability to work independently or as part of a team</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Constant opportunities for learning and professional growth</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">Is there a high demand for full-stack developers?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">Yes, there is a high demand for full-stack developers in the job market. Companies value the versatility and comprehensive skill set that full-stack developers bring, as they can handle both front-end and back-end tasks. This makes them highly adaptable and efficient in a team setting. The growing reliance on web applications and digital services further drives the need for skilled full-stack developers, making it a lucrative and stable career choice.</p>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">Will I work on real projects during the course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">Certainly! At TechU's full-stack developer course, you can expect to work on real projects as part of the curriculum. These hands-on projects are designed to simulate real-world scenarios, giving you valuable practical experience. By applying the skills learned in class to these projects, you'll not only solidify your understanding but also build a portfolio that showcases your abilities to future employers. This emphasis on real projects at TechU ensures that you're well-prepared to tackle the challenges of full-stack development in the professional world.</p>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">Do I get a certificate after completing the course?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">Yes, best full-stack developer courses offer a certificate of completion upon finishing the program. This certificate serves as a formal recognition of the skills and knowledge you've acquired. It can be a valuable addition to your resume and LinkedIn profile, helping to demonstrate your qualifications to potential employers. While the certificate itself is important, the practical skills and experience you gain during the course are equally, if not more, valuable.
                                    </p>
                                </div>
                            </div>
                            <div className="readmore-question-answer-container">
                                <div
                                    className="question-icon">
                                    <h4 className="readmore-heading">What are the responsibilities of a full stack developer?</h4>
                                </div>
                                <div className="ans-container">
                                    <p className="readmore-answer">A Full Stack Developer is responsible for</p>
                                    <ol className="list-item">
                                        <li>
                                            <p className="readmore-answer">Design User Interfaces: Create user-friendly and visually appealing interfaces.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Ensure Responsiveness: Make applications work well on all devices.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Use Frameworks: Implement features using frameworks like React or Angular.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Manage Databases: Handle data storage with SQL, NoSQL and MongoDB databases.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Create APIs: Develop APIs for front-end and back-end communication.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Use Version Control: Track and manage code changes with Git.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Deploy Applications: Launch and manage applications in production.</p>
                                        </li>
                                        <li>
                                            <p className="readmore-answer">Ensure Security: Implement security measures in web applications.</p>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </article>
                </section>

                <div className='readmore'>
                    <div class="main-section">
                        <div class="block-div-1">
                            <div >
                                <h3 class="heading-one trending">Courses in Trend</h3>
                                <div class="trending-blocks"><a href="#" class="city-block track-class inline-div">
                                    <div>MERN Stack Developement Course</div>
                                </a><a href="#" class="city-block track-class inline-div">
                                        <div>MEAN Stack Developement Course</div>
                                    </a>
                                    <a href='#' class="city-block track-class inline-div">
                                        <div>Data Analyst Course</div>
                                    </a><a href='#' class="city-block track-class inline-div">
                                        <div>Automation Testing Course</div>
                                    </a></div>
                            </div>
                        </div>
                        <div>
                            <h3 class="heading-one trending">Other cities with Full Stack Developer Courses</h3>
                            <div class="city-box"><a
                                href="#" class="city-block track-class inline-div">
                                <div>Bangalore</div>
                            </a><a href="#"
                                class="city-block track-class inline-div">
                                    <div>Pune</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Mumbai</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Ahmedabad</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Coimbatore</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Chennai</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Chandigarh</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Noida</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Kolkata</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Kochi</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Delhi</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Bhubaneswar</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Visakhapatnam</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Vijayawada</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Kanpur<br /></div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Nagpur</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Madurai<br /></div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Gurgaon</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Jaipur</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Indore</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Trivandrum</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Guwahati </div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Bhopal</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Patna</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Aurangabad<br /></div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Trichy</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Salem</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Thane</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Kerala</div>
                                </a><a href="#"
                                    class="city-block track-class inline-div">
                                    <div>Mysore</div>
                                </a></div>
                        </div>
                    </div>
                </div>

                <div id="footer" data-sectionid="sections" class="footer-main">
                    <div>
                        <div class="footer-container">
                            <div class="footer-container-row-1">
                                <div class="footer-container-information-1"><a href="#"
                                    class="footer-container-logo tbtn inline-div">
                                    <div class="TechU-logo">
                                        <img
                                            src={techuLogo}
                                            alt="TechU Image" width="70%" loading="lazy" />
                                    </div>
                                </a>
                                    <div class="footer-container-col-1 contact-us email">
                                        <h6 class="heading-two">Reach Us</h6>
                                        <div class="block-div-2"><a
                                            href="tel:+919154747782" target="_blank"
                                            class="wa-btn tbtn inline-div">
                                            <div class="wa-icon">
                                                < FaWhatsapp style={{ color: "#ffffff", background: "linear-gradient(#23b33a, #23b33a, #23b33a)", borderRadius: "50%", width: "30px", height: "30px", padding: "0px" }} />
                                            </div>
                                            <div>
                                                <div class="wa-link">+91 9154747782<br />(WhatsApp only)</div>
                                            </div>
                                        </a><a href="mailto:techutraining@gmail.com"
                                            class="em-btn tbtn inline-div">
                                                <div class="em-icon ">
                                                    <img src={email} />
                                                </div>
                                                <div>
                                                    <div class="wa-link">techutraining@gmail.com</div>
                                                </div>
                                            </a></div>
                                    </div>
                                </div>

                                <div class="footer-container-row-2">
                                    <div class="block-div-3">
                                        <h6 class="heading-two navigate-links">Quick Links</h6>
                                        <div class="block-div-4">
                                            <div class="footer-container-col-1">
                                                <ul role="list" class="link-div-1 links no-style-list">
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Home<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Academy<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Hire with us<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Contact Us<br /></a></li>
                                                    <li class="link-menu-items-22"><a href="#"
                                                        class="link-item-footer tbtn">Blog<br /></a></li>
                                                </ul>
                                            </div>
                                            <div class="block-div-17">
                                                <ul role="list" class="link-div-1 links _two no-style-list">
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">About Us<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Reviews <br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">Community<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">4.0 Champions<br /></a></li>
                                                    <li class="menu-div-item-1"><a href="#"
                                                        class="link-item-footer tbtn">TechU Review</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="footer-container-col-1 contact-us email">
                                        <h6 class="heading-two">Payment Methods</h6>
                                        <div class="block-div-6">
                                            <div class="payment-methods-images"><img
                                                src={visa}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={credit}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={payment}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={razorpay}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={upi}
                                                alt='payment method' loading='lazy' /></div>
                                            <div class="payment-methods-images"><img
                                                src={rupay}
                                                alt='payment method' loading='lazy' /></div>
                                        </div>
                                        <div class="block-div-7">
                                            <div>
                                                <div class="location-div"></div>
                                            </div>
                                            <div>
                                                <div class="location-text">TechU, #303B Images Capital Park, Madhapur, Hyderabad - 500081</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="block-div-8"></div>

                            <div class="block-div-9">
                                <div class="social-div">

                                    <a id="footer-facebook-profile-link" rel="nofollow"
                                    href="https://www.facebook.com/techubyabilio" target="_blank"
                                    class="link-block-45 tbtn inline-div">

                                    <div class="social-icons ">
                                        <img
                                            src={fb}
                                            alt='socal media' loading='lazy' />
                                            </div>
                                </a>

                                    <a id="footer-instagram-profile-link" rel="nofollow" href="https://www.instagram.com/techu_by_abilio/"
                                        target="_blank" class="link-block-45 tbtn inline-div">
                                        <div class="social-icons "><img
                                            src={insta}
                                            alt='socal media' loading='lazy' /></div>
                                    </a>
                                    <a id="footer-youtube-profile-link" rel="nofollow" href="https://www.youtube.com/@Tech_U820/featured"
                                        target="_blank" class="link-block-43 tbtn inline-div">
                                        <div class="social-icons "><img
                                            src={youtube}
                                            alt='socal media' loading='lazy' /></div>
                                    </a>

                                    <a id="footer-youtube-profile-link" rel="nofollow" href="https://www.linkedin.com/in/techu-by-abilio-a105b731b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                                        target="_blank" class="link-block-43 tbtn inline-div">
                                        <div class="social-icons "><img
                                            src={linkedin}
                                            
                                            alt='socal media' loading='lazy' width={"20px"}/></div>
                                    </a>
                                    
                                    </div>
                                <div class="block-div-10">
                                    <div class="block-div-11">
                                        <div class="privacy-item-link"><a href="#"
                                            class="link-items  tbtn">Privacy Policy</a><br /></div>
                                        <div class="cookie-item-link "><a href="#"
                                            class="link-items  tbtn">Cookie Policy</a><br /></div>
                                        <div class="terms-item-link "><a href="#" class="link-items  tbtn">Terms and Conditions</a><br /></div>
                                        <div class="grievance-item-link"><a href="#"
                                            class="link-items  tbtn">Grievance Redressal</a><br /></div>
                                        <div class="grievance-item-link-2"><a href="#" class="link-items  tbtn">Corporate Information</a><br /></div>
                                        <div id="footer-vision-and-value-statement" class="grievance-item-link-1"><a
                                            href="#"
                                            class="link-items  tbtn">Vision and Values</a><br /></div>
                                    </div>
                                </div>
                            </div>

                            <div class="block-div-8"></div>

                            <div id="ct" className='ct-main'>
                                <h6 class="heading-two cts new">Course Tracks</h6>
                                <div class="block-div-12 ">
                                    <div class="block-div-13 "><a id="footer-mern-stack-developer-ct"
                                        href="#"
                                        class="footer-navigate-link-2  heading-size  tbtn">MERN Stack Developer course</a></div>
                                    <div class="block-div-13 "><a id="footer-full-stack-developer-ct"
                                        href="#"
                                        class="footer-navigate-link-2 heading-size tbtn">Full Stack Developer course</a>
                                        <div class="block-div-14 ">
                                            <div class="full-stack-dev-c"><a href="#"
                                                class="footer-navigate-link-2  para-size  tbtn">Hyderabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bangalore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Pune</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mumbai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Delhi</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Ahmedabad</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Coimbatore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chennai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chandigarh</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Noida</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kolkata</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kochi  </a>
                                                <div class="tb-1  ds-hide mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhubaneswar</a>
                                                <div class="tb-1 ">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Visakhapatnam</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Vijayawada</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Gurgaon</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Jaipur  </a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Indore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kanpur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Nagpur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Madurai</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhopal</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trivandrum</a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Guwahati</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Patna  </a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Aurangabad </a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trichy  </a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Salem</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Thane</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kerala</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mysore</a>
                                                <div class="tb-1  ds-hide mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Telugu</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Tamil</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hindi</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="block-div-13 "><a href="#" class="footer-navigate-link-2  heading-size  tbtn">Data
                                        Analytics course</a>
                                        <div class="block-div-15">
                                            <div class="data-analytics-c"><a href="#"
                                                class="footer-navigate-link-2  para-size  tbtn">Hyderabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bangalore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Pune</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mumbai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Delhi</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chennai</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kolkata</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#" class="footer-navigate-link-2  para-size  tbtn">Chandigarh</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Ahmedabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Coimbatore</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Gurgaon  </a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Noida</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kerala</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Nagpur</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Indore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Jaipur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Lucknow</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Thane</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhopal</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Dehradun</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Bhubaneswar</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Mysore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Vadodara</a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kanpur</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trivandrum</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Surat</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Nashik  </a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Madurai   </a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Patna</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Aurangabad</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Ghaziabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Meerut</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Guwahati</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kolhapur</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Kochi</a>
                                                <div class="tb-1  ds-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Trichy</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Telugu</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Tamil</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hindi</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="block-div-13 "><a href="#" class="footer-navigate-link-2  heading-size  tbtn">QA /
                                        Automation Testing course</a>
                                        <div class="block-div-14 ">
                                            <div class="block-div-16"><a href="#"
                                                class="footer-navigate-link-2  para-size  tbtn">Bangalore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hyderabad</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Chennai</a>
                                                <div class="tb-1  mb-hide">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Pune</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Coimbatore</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Noida</a>
                                                <div class="tb-1  mb-hide">|</div><a
                                                    href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Telugu</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Tamil</a>
                                                <div class="tb-1 ">|</div><a href="#"
                                                    class="footer-navigate-link-2  para-size  tbtn">Hindi</a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tech