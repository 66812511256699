import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import Main from "./Main";
import { FiPhone } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa6";
import techuLogo from "./images/techu-navbar-logo.png";
import { useEffect, useState } from "react";
import { IoMenuOutline } from "react-icons/io5";
import ThankYouPage from "./components/ThankYouPage";

function App() {
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const whatsappNumber = '9154747782';
  const whatsappMessage = "Hello, I would like to know more about your Full stack with Generative AI course.";
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;

  const handleWhatsAppClick = () => {
    window.open(whatsappLink, "_blank");
  };

  const toggleNavbar = () => {
    setCollapsed(prev => !prev);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setCollapsed(true);
  };

  const handleNavigationClick = (id) => {
    if (location.pathname === '/Thankyou') {
      navigate('/');
      scrollToSection(id);
    } else {
      navigate('/');
      setTimeout(() => {
        scrollToSection(id);
      }, 0);
    }
  };

  return (
    <div>
      <div className="sticky-top" style={{ backgroundColor: "#ffffff" }}>
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid navbar-container">
            <a className="navbar-brand" href="#">
              <img src={techuLogo} width={150} alt="TechU Logo" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded={!collapsed}
              aria-label="Toggle navigation"
              onClick={toggleNavbar}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className={`collapse navbar-collapse ${collapsed ? "" : "show"}`} id="navbarNav">
              <div className="navbar-nav ms-auto">
                <div className="navbar-brand-1">
                  <img src={techuLogo} width={150} alt="TechU Logo" />
                  <button
                    className="navbar-toggler navbar-toggle-mobile"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={!collapsed}
                    aria-label="Toggle navigation"
                    onClick={toggleNavbar}
                  >
                    <IoMenuOutline className="navbar-mobile-button" size={25} />
                  </button>
                </div>
                <a className="nav-items-header track-button w-nav-link" style={{ cursor: "pointer" }} onClick={() => handleNavigationClick("overview")}>
                  Overview
                </a>
                <a className="nav-items-header track-button w-nav-link" style={{ cursor: "pointer" }} onClick={() => handleNavigationClick("syllabus")}>
                  Syllabus
                </a>
                <a className="nav-items-header track-button w-nav-link" style={{ cursor: "pointer" }} onClick={() => handleNavigationClick("Why-TechU?")}>
                  Why TechU?
                </a>
                <a className="nav-items-header track-button w-nav-link" style={{ cursor: "pointer" }} onClick={() => handleNavigationClick("Testimonials")}>
                  Testimonials
                </a>
                <a className="nav-items-header track-button w-nav-link" style={{ cursor: "pointer" }} onClick={() => handleNavigationClick("services")}>
                  Services
                </a>
                <a className="nav-items-header track-button w-nav-link" style={{ cursor: "pointer" }} onClick={() => handleNavigationClick("Help")}>
                  Help & Support
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      <Routes>
        <Route path="/" element={<Main handle={handleNavigationClick} />} />
        <Route path="/thankyu" element={<ThankYouPage />} />
      </Routes>

      <div className="sticky-bottom">
        <div id="chat-with-us-div" className="whatsappdiv-container-mobile-view">
          <div className="whatsappdiv-block-mobile-view whatup-home">
            <a className="phone-icon-div" href="tel:+919154747782" target="_blank" rel="noopener noreferrer">
              <FiPhone size={40} className="phone-icon" style={{ color: "#ffffff" }} />
            </a>
            <div className="whapup-div" onClick={handleWhatsAppClick}>
              <div className="whatup-img">
                <FaWhatsapp style={{ color: "#ffff", fontSize: "135px", marginTop: "5px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
