import React, { useState } from "react";
import { MdErrorOutline } from "react-icons/md";
import "./techuAdvantages.css";
import {
  FaYoutube,
  FaUser,
  FaGlobe,
  FaThumbsUp,
  FaRocket,
  FaClock,
  FaBriefcase,
} from "react-icons/fa";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import { MdComputer } from "react-icons/md";
import { RiStackFill } from "react-icons/ri";
import { MdDone } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";

const ComparisonTable = ({ handleclick }) => {
  const [currentTab, setCurrentTab] = useState("Tab 1");
  const [openFAQ, setOpenFAQ] = useState(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const tabsFaq = [
    { id: "Tab 1", label: "Course Details" },
    { id: "Tab 2", label: "Qualification" },
    { id: "Tab 3", label: "Job Assistance" },
    { id: "Tab 4", label: "Certificate" },
    { id: "Tab 5", label: "Tuition" },
  ];

  const faqs = {
    "Tab 1": [
      {
        id: "course-details-1",
        question: "What does the Full Stack Developer course entail?",
        answer: [
          "The TechU Full Stack Developer course is an advanced program designed to equip you with essential skills for a career as a full-stack developer. It covers both front-end and back-end development, including technologies such as HTML, CSS,Bootstrap, JavaScript, Spring Boot,Angular and React JS. Created by industry experts from top companies, the curriculum ensures you are prepared for the job market. You'll build a portfolio through hands-on projects to showcase your skills to potential employers. Start your IT career with TechU's highly rated Full Stack Developer course.",
        ],
      },
      {
        id: "course-details-2",
        question:
          "What is the length of the Full Stack Developer course in Hyderabad?",
        answer: [
          "The Full Stack Developer course is designed to be completed in 6 months if you follow the structured timeline. However, you also have the option to progress at your own pace.",
        ],
      },
      {
        id: "course-details-3",
        question:
          "What makes TechU’s online learning platform highly effective?",
        answer: [
          "TechU provides more than just online classes; it offers a comprehensive learning environment. Features like 24x7 Online Labs enable immediate practice, and discussion forums allow you to get help from experts whenever needed. You can revisit sessions at any time and access summary notes that consolidate key concepts for easy reference. The platform also tracks your progress to keep you motivated, and interacting with fellow learners enhances your engagement.",
        ],
      },
      {
        id: "course-details-4",
        question: "Are the timings for the TechU program flexible?",
        answer: [
          "Yes,you can study at times that suit you best, though it's advisable to maintain a regular schedule. Live webinars occur at specific times, usually on weekends or evenings, and attendance is required for these sessions. Overall, the program is designed to be interactive and adaptable to your schedule.",
        ],
      },
      {
        id: "course-details-5",
        question: "What should I do if I have questions while studying?",
        answer: [
          "It's normal to have questions while learning. At TechU, you can get answers from domain experts from 9:30 AM to 6:30 PM daily, ensuring a swift response. You can post your questions in the discussion forums and receive solutions or clarifications. Additionally, reviewing questions and answers from other students can provide further insights.",
        ],
      },
      {
        id: "course-details-7",
        question:
          "How can I balance learning with my college or job commitments?",
        answer: [
          "The TechU program is self-paced, allowing you to study after your college or work hours and on weekends. This flexibility helps you integrate learning with your existing schedule.",
        ],
      },
    ],
    "Tab 2": [
      {
        id: "eligibility-1",
        question:
          "Who can enroll in the Full Stack Developer course in Hyderabad?",
        answer: [
          "The Full Stack Developer course in Hyderabad is open to individuals from various academic backgrounds, including B.Tech (all IT and non-IT branches), BSc, B.Com, BBA, MBA, and others. There is no minimum CGPA requirement and no prior coding experience is necessary.",
        ],
      },
      {
        id: "eligibility-2",
        question:
          "Why is the program accessible to individuals from diverse backgrounds?",
        answer: [
          "TechU's program is designed to prepare anyone for a tech career in a short time frame. Your academic background, grades, or past challenges do not limit your eligibility. You don’t need previous coding experience; everything is taught from the basics. Our dedicated trainers use effective methods to make learning straightforward and memorable, helping you build confidence and passion for software development. By the end of the program, you'll have a strong portfolio and be in demand by employers. The program is especially beneficial for final-year students, job seekers, and those aiming to switch careers.",
        ],
      },
      {
        id: "eligibility-3",
        question:
          "How are graduates with degrees like B.A, B.Com, or MBA finding tech jobs?",
        answer: [
          "Your degree doesn’t hinder your ability to secure a tech job. Companies prioritize practical skills over academic backgrounds. By working on real-world projects during the program, your resume will stand out. Many TechU graduates with diverse degrees have successfully entered the tech industry. For instance, Sonali, with a background in Chemistry, secured a tech job with a ₹7 Lakhs annual salary at ADF, a US-based tech firm.",
        ],
      },
      {
        id: "eligibility-4",
        question:
          "How are individuals with career gaps managing to land tech jobs?",
        answer: [
          "Career gaps are not a barrier to getting a tech job. Companies value practical skills over employment gaps. Through the program, you will develop skills that are in demand. Many graduates with career gaps have successfully transitioned into tech roles. For example, Umamaheswari, who had a career break after graduation, was eventually placed as a Business Analyst at EXL Service. You can read more about similar success stories in our reviews.",
        ],
      },
      {
        id: "eligibility-5",
        question: "Can graduates from non-CS fields find tech jobs?",
        answer: [
          "Your field of study is not an obstacle to securing a tech job. Companies are more interested in practical skills than your academic background. By completing real-world projects during the program, your skills will become the primary focus. Many TechU graduates from non-CS disciplines have successfully entered the tech industry. For instance, Sushanth, a civil engineering graduate, secured a Full Stack Developer role at needl.ai with a salary of ₹11 lakhs per year.",
        ],
      },
    ],
    "Tab 3": [
      {
        id: "job-assistance-1",
        question: "What kind of job assistance will I receive?",
        answer: [
          "You will receive support with job placement, including help with resume preparation, interview skills, and career advice. This assistance is designed to help you secure a job in your field of study.",
        ],
      },
      {
        id: "job-assistance-2",
        question: "How long will the job assistance be available to me?",
        answer: [
          "Job assistance will be available for 12 months from the date of joining. During this period, you will have access to resources and support to help you find a job.",
        ],
      },
    ],
    "Tab 4": [
      {
        id: "certification-1",
        question:
          "Will I get a certificate after completing the Full Stack Developer course?",
        answer: [
          "Yes, you’ll receive a certificate upon completion of the course. TechU's certification is highly valued by companies, indicating that you have acquired the necessary skills.",
        ],
      },
      {
        id: "certification-2",
        question: "What does the certification represent?",
        answer: [
          "The certification represents your readiness for a job and demonstrates that you possess the skills that employers are looking for. It shows that you have completed industry-relevant projects and training.",
        ],
      },
      {
        id: "certification-3",
        question:
          "Why is this certification considered more valuable than regular study certificates?",
        answer: [
          "This certification is awarded after you complete industry-aligned projects, assignments, and tests designed by experienced developers. It is recognized for its rigorous evaluation process, giving you an advantage in the job market and during interviews.",
        ],
      },
    ],
    "Tab 5": [
      {
        id: "payment-1",
        question: "Can I pay for TechU  using an EMI option?",
        answer: [
          `Yes, we offer EMI options for credit card payments. You can select the EMI payment method during checkout for further details.`,
        ],
      },
      {
        id: "payment-2",
        question: "What is the refund policy?",
        answer: [
          `For details on our refund policy, please visit: <a href='https://techu.in/#/' target="_blank" >https://techu.in/#/</a> `,
        ],
      },
    ],
  };

  const handleFAQClick = (faqId) => {
    setOpenFAQ(openFAQ === faqId ? null : faqId);
  };
  return (
    <>
      <div className="why-join-intensive">
        <div id="Why-TechU?" className="why-join-intensive-container-div">
          <div id="TechU-advantage" className="why-int-content-container">
            <div className="why-int-heading-container">
              <div className="h2a-4">Why Choose TechU?</div>
            </div>
            <div className="why-int-comaprison-table">
              <div className="comparison-row-div">
                <div className="comparison-row-heading-div"></div>
                <div className="comparison-rows-container">
                  <div className="comparison-odd-rows odd">
                    <div>Program Structure</div>
                  </div>
                  <div className="comparison-even-rows even">
                    <div className="comparison-txt2">Help & Support</div>
                  </div>
                  <div className="comparison-odd-rows odd">
                    <div>North Indian Training Specialists</div>
                  </div>
                  <div className="comparison-even-rows even">
                    <div className="comparison-txt2">
                      Placement Achievements
                    </div>
                  </div>
                  <div className="comparison-odd-rows odd">
                    <div className="comparison-txt2">Career Opportunities</div>
                  </div>
                  <div className="comparison-even-rows even">
                    <div>Hands-on Projects</div>
                  </div>
                  <div className="comparison-odd-rows odd">
                    <div className="comparison-txt2">Lab Availability</div>
                  </div>
                  <div className="comparison-odd-rows">
                    <div className="comparison-txt2">
                      Interview Skills Workshops
                    </div>
                  </div>
                  <div className="comparison-even-rows">
                    <div className="comparison-txt2">
                      Alumni Interview Insights
                    </div>
                  </div>
                  <div className="comparison-odd-rows">
                    <div className="comparison-txt2">Interview Scheduling</div>
                  </div>
                  <div className="comparison-even-rows last-row-height">
                    <div className="comparison-txt2">
                      Salary Negotiation Tips
                    </div>
                  </div>
                </div>
              </div>
              <div className="comparison-column-div">
                <div className="comparison-int-col-container-2">
                  <div className="comparison-col1-heading-2">
                    {" "}
                    TechU Full Stack Training
                  </div>
                  <div className="comparison-txt1">
                    Designed as per Current Market Standards
                  </div>
                  <div className="comparison-txt1">
                    9:30AM - 6:30PM Doubts Clarified by
                    <br /> Dedicated Mentors.
                  </div>
                  <div className="comparison-txt1">
                    Industry Leaders & Innovators
                  </div>
                  <div className="comparison-txt1">
                    Results Across All Fields of Study
                  </div>
                  <div className="comparison-txt1">Endless Opportunities</div>
                  <div className="comparison-txt1">
                    Real-Time Project Experience for a Stronger Resume
                  </div>
                  <div className="comparison-txt1">
                    Seamless Access to Online Labs – No Installation Needed
                  </div>
                  <div className="comparison-txt1">
                    Expert Panels for Tech & HR Needs
                  </div>
                  <div className="comparison-txt1">Unlimited Access </div>
                  <div className="comparison-content-div-4">
                    <MdDone className="done-tick" />
                  </div>
                  <div className="comparison-txt">
                    <div className="comparison-content-div-4">
                      Roadmap to Higher Salaries
                    </div>
                  </div>
                </div>
                <div className="comparison-other-col-container-2">
                  <div className="comparison-col2-heading">
                    Other Learning Institutions
                  </div>
                  <div className="comparison-txt1">
                    Lacking Industry Alignment
                  </div>
                  <div className="comparison-txt1"> 4-5 hours per day</div>
                  <div className="comparison-txt1">
                    Not Involving Real-World Projects
                  </div>
                  <div className="comparison-txt1">
                    Targeted at Certain Branches and Degrees
                  </div>
                  <div className="comparison-txt1">Limited </div>
                  <div className="comparison-txt1">~2-4 real time projects</div>
                  <div className="comparison-txt1">~3-4 hours/day</div>
                  <div className="comparison-content-div-4">
                    <RxCross2 className="cross-tick" />
                  </div>
                  <div className="comparison-content-div-4">
                    <RxCross2 className="cross-tick" />
                  </div>

                  <div className="comparison-content-div-4">
                    <RxCross2 className="cross-tick" />
                  </div>
                  <div className="comparison-content-div-4">
                    <RxCross2 className="cross-tick" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-dyn-list">
            <div role="list" className="w-dyn-items">
              <div role="listitem" className="w-dyn-item">
                <div className="date-changes-cms-collection-container left-aligin plr-16">
                  <div className="dates-changing-ctas">
                    <div className="embed-btn w-embed">
                      <div className="dates-changing-ctas">
                        <button
                          id="book-a-free-demo-intensive-advantages"
                          onClick={() => handleclick("hero-section")}
                          className="book-a-free-demo-main-btn track-class w-button scrap-btn"
                        >
                          Book a Free Demo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="services" className="pricing-2">
        <div className="main-container-div">
          <h2 className="h2a">
            Pricing Details for Full Stack Developer Course
          </h2>
          <div className="pricing-tabs">
            <div className="w-tabs">
              <div className="pricing-tab-menu w-tab-menu">
                <h1> Services</h1>
              </div>
              <div className="tabs-content-29 w-tab-content">
                <div data-w-tab="Tab 1" className="w-tab-pane w--tab-active">
                  <div className="pricing-postpaid-tab-content">
                    <div className="whats-included-content-container">
                      <div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            <MdComputer
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                              alt=""
                            />
                          </div>
                          <div>Core IT Concepts</div>
                        </div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            <RiStackFill
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                            />
                          </div>
                          <div> Full Stack Specialization</div>
                        </div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            {" "}
                            <FaYoutube
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                            />
                          </div>
                          <div>
                            Daily: 3 Hours in Class and <br />3 Hours in Labs
                          </div>
                        </div>
                        <div className="whats-included-features-div">
                          <div>
                            <div className="html-embed-41918 w-embed">
                              {" "}
                              <FaUser
                                className="pricing-logos-post-paid post-paid-icons"
                                loading="lazy"
                              />
                            </div>
                          </div>
                          <div>Elite Trainers: IIT Alumni & Experts </div>
                        </div>
                      </div>
                      <div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            {" "}
                            <FaGlobe
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                            />
                          </div>
                          <div>Explore Unlimited Opportunities</div>
                        </div>
                        <div className="whats-included-features-list-div">
                          <div className="features-list-div">
                            <div className="w-embed">
                              {" "}
                              <FaThumbsUp
                                className="pricing-logos-post-paid post-paid-icons"
                                loading="lazy"
                              />
                            </div>
                            <div className="whats-included-txt1">
                              Job Placement Support
                            </div>
                          </div>
                          <ul role="list" className="features-list">
                            <li>
                              <div>Comprehensive Aptitude Training</div>
                            </li>
                            <li>
                              <div>Professional Soft Skills Training</div>
                            </li>
                            <li>
                              <div>Expert Resume Preparation</div>
                            </li>
                            <li>
                              <div> Mock Interviews</div>
                            </li>

                            <li>
                              <div>Scheduling Interviews</div>
                            </li>
                            <li>
                              <div>Exclusive Placement Portal Access</div>
                            </li>
                            <li>
                              <div> Offline Placement Drives</div>
                            </li>
                            <li>
                              <div>Salary Negotiation with Companies</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            {" "}
                            <AiOutlineQuestionCircle
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                            />
                          </div>
                          <div>
                            9:30AM - 6:30PM Doubts Clarified by Dedicated
                            Mentors.
                          </div>
                        </div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            {" "}
                            <FaRocket
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                            />
                          </div>
                          <div>
                            Real-Time Project Experience for a Stronger Resume
                          </div>
                        </div>
                        <div className="whats-included-features-div">
                          <div className="w-embed">
                            {" "}
                            <FaClock
                              className="pricing-logos-post-paid post-paid-icons"
                              loading="lazy"
                            />
                          </div>
                          <div>
                            Seamless Access to Online Labs – No Installation
                            Needed
                          </div>
                        </div>
                        <div
                          id="scrap-btn-py"
                          className="pricing-logos-container"
                        >
                          <div>
                            <div
                              data-delay="0"
                              data-hover="true"
                              className="tooltip-dropdown w-dropdown"
                            >
                              <div
                                onMouseEnter={() => setShowTooltip(true)}
                                onMouseLeave={() => setShowTooltip(false)}
                                className="tooltip-icon"
                              >
                                <div className="tooltip-text">
                                  <div className="logo-height-2 w-embed">
                                    <FaBriefcase
                                      className="pricing-logos-post-paid post-paid-icons"
                                      loading="lazy"
                                    />
                                  </div>
                                  <div className="text-block-941248789-copy light-red _475569">
                                    TechU is a Job
                                    <br />
                                    Assistance Program.
                                  </div>
                                  <div className="image-812882270 w-embed">
                                    <MdErrorOutline />{" "}
                                  </div>
                                  {showTooltip && (
                                    <div className="tooltip-content">
                                      <div className="tooltip-title">
                                        TechU focuses on building
                                        industry-relevant skills and offers
                                        extensive assistance to help you secure
                                        a job. Over the past year, many
                                        companies have hired our students. Our
                                        placement support includes mock
                                        interviews, senior students’ interview
                                        experiences, and soft-skills training,
                                        resume preparation, and placement tests.
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="post-paid-collection w-dyn-list">
                      <div role="list" className="w-dyn-items">
                        <div role="listitem" className="w-dyn-item">
                          <div className="date-changes-cms-collection-container left-aligin">
                            <div className="dates-changing-ctas">
                              <div className="embed-btn w-embed">
                                <button
                                  id="book-a-free-demo-postpaid-tab-pricing"
                                  onClick={() => handleclick("hero-section")}
                                  className="book-a-free-demo-main-btn track-class w-button scrap-btn"
                                >
                                  Book a Free Demo
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="Help" className="faq-section">
        <div className="faq-main-container-y">
          <h2 className="faq-main-heading-y">How Can We Help?</h2>
          <div className="faqs-all-tabs-main-container-y">
            <div className="tabs-y faq-w-tabs">
              <div className="faqs-all-tabs-y w-tab-menu">
                {tabsFaq.map((tab) => (
                  <a
                    key={tab.id}
                    data-w-tab={tab.id}
                    className={`faq-tab-link-y track-button w-inline-block w-tab-link ${currentTab === tab.id ? "w--current" : ""
                      }`}
                    onClick={() => setCurrentTab(tab.id)}
                  >
                    <div>{tab.label}</div>
                  </a>
                ))}
              </div>
              <div className="faqs-tab-content-y w-tab-content">
                <div className="question-answers-container-y">
                  {(faqs[currentTab] || []).map((faq) => (
                    <div key={faq.id} className="faq-qaa-main-conatainer-y">
                      <div
                        id={faq.id}
                        className={`faq-q-icon-container-y track-button ${openFAQ === faq.id ? "open" : ""
                          }`}
                        onClick={() => handleFAQClick(faq.id)}
                      >
                        <h4 className="faq-q-h-y">{faq.question}</h4>
                        <div
                          className="icon-14"
                          style={{
                            transform:
                              openFAQ === faq.id
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                            transition: "transform 0.4s ease",
                          }}
                        >
                          <div className="faq-dropdown-arrow"></div>
                        </div>
                      </div>
                      <div
                        className="faq-ans-y"
                        style={{
                          display: openFAQ === faq.id ? "block" : "none",
                        }}
                      >
                        <div className="faq-anws-container-y">
                          {faq.answer.map((paragraph, index) => (
                            <p
                              key={index}
                              className="faq-ans-p-y"
                              dangerouslySetInnerHTML={{ __html: paragraph }}
                            ></p>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing-2">
        <div className="main-container-div">
          <div>
            <div className="h2a">Hyderabad's Full Stack Developer Course</div>
            <div className="job-opp-info-copy">
              Hyderabad, the illustrious City of Pearls, is a key urban center
              in South India and the capital of
              <a
                href="https://en.wikipedia.org/wiki/Telangana"
                id="telangana-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                Telangana
              </a>
              . The city, home to over 10 million people, is distinguished by
              its rich
              <a
                href="https://hyderabad.telangana.gov.in/history/"
                id="history-and-culture-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                historical background and cultural heritage
              </a>
              that is reflected in its monuments, food, and festivals.
              <br />
              <br />
              Hyderabad stands as a significant hub for IT and tech industries,
              presenting excellent opportunities for young professionals. With
              its robust
              <a
                href="https://wellfound.com/startups/l/hyderabad/technology"
                id="startup-ecosystem-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                startup ecosystem
              </a>
              and incubation facilities, the city is a prime choice for
              entrepreneurs and startups aiming to establish themselves. For
              those seeking to advance their tech careers, Hyderabad offers
              various training programs, including MERN stack courses, full
              stack web development courses, and comprehensive full stack
              developer training.
              <br />
              <br />
              The city presents an exciting lifestyle that blends historical
              charm with modern conveniences. From traditional markets to sleek
              shopping centers, and a diverse array of cafes, restaurants, and
              pubs, Hyderabad is a top destination for food enthusiasts.
              <br />
              <br />
              For a memorable visit, youngsters should explore Hyderabad's top
              attractions:
              <a
                href="https://hyderabad.telangana.gov.in/tourist-place/charminar/"
                id="charminar-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                Charminar
              </a>
              ,
              <a
                href="https://en.wikipedia.org/wiki/Golconda"
                id="golconda-fort-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                Golconda Fort
              </a>
              , Hussain Sagar Lake, Ramoji Film City,
              <a
                href="https://www.salarjungmuseum.in/History-of-SJM.html"
                id="salarjungmuseum-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                Salar Jung Museum
              </a>
              , Chowmohalla Palace,
              <a
                href="https://hyderabad.telangana.gov.in/tourist-place/birla-mandir/"
                id="birla-mandir-fullstack-course-in-hyd"
                rel="nofollow"
                target="_blank"
                className="link-block-blue-color track-class"
              >
                Birla Mandir
              </a>
              , and Laad Bazaar.
              <br />
              <br />
              The city's lively nightlife, affordable cost of living, and robust
              infrastructure make it an ideal place for young professionals and
              students to thrive. Whether you're interested in a full
              development course or full stack developer training, Hyderabad
              offers an engaging environment for career growth and success.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComparisonTable;
