import React, { useRef, useState } from "react";
import "./techuHome.css";
import ratingStarts from "../images/rating-starts.svg";
// import formStudent from "../images/from-img.avif";
import { MdOutlineFileDownload } from "react-icons/md";
import { FaChevronDown, FaPlus, FaWhatsapp } from "react-icons/fa6";
import { FiMinus } from "react-icons/fi";
import axios from "axios";
import highestPakage from "../images/highestPakage.png";
import atulSharma from '../images/AtulSharma-Trainer.jpg';
import video from '../images/company-image1.png'

// feacture icons
import intershipIcon from "../images/intershipIcon.png";
import eligibilityIcon from "../images/eligibilityIcon.png";
import certificateIcon from "../images/certificateIcon.png";
import courseDurationIcon from "../images/courseDurationIcon.png";

// reviews profile-icons
import chaitanya from "../images/chaitanya.png";
import mahendra from "../images/mahendra.png";
import prem from "../images/prem.png";
import sakshi from "../images/sakshi.png";
import akhil from "../images/akhil.png";
import anusha from "../images/anusha.png";


import formStudent from "../images/demo-embed-form-person-image.jpg";


// course ouverviws images
import livesections from "../images/livesections.png";
import merntoring from "../images/Mentersuport.png";
import oneOnOne from "../images/oneOnOne.png";
import plecement from "../images/placement.png";
import mockinterviews from "../images/mockInterviews.png";
import softskills from "../images/softskills.png";
import Masterclasses from "../images/Masterclasses.png";
import { useNavigate } from "react-router-dom";

const TechuHome = ({ handleclick }) => {
  const [showAll, setShowAll] = useState(false);
  const [errorMessage, setErrorMessage] = useState("")
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    nativeState: "",
    education: "",
    course: "FullStack"
  });
  const whatsappNumber = "9154747782";
  const whatsappMessage = "Hello, I would like to know more about your Full stack with Generative AI course.";
  const whatsappLink = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(whatsappMessage)}`;
  const navigate = useNavigate()
  const handleWhatsAppClick = () => {
    window.open(whatsappLink, "_blank");
  };

  const handledemo = () => {
    console.log("scroll")
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let formErrors = {};

    if (!formData.name.trim()) {
      formErrors.name = "Name is required";
    }

    if (!formData.mobile.trim()) {
      formErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      formErrors.mobile = "Mobile number must be 10 digits";
    }

    if (!formData.nativeState.trim()) {
      formErrors.nativeState = "State is required";
    }

    if (!formData.education) {
      formErrors.education = "Please select a qualification";
    }

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        const res = await axios.post("https://server.techu.in/Demoregister", formData);
        if (res.status === 201) {
          setFormData({
            name: "",
            mobile: "",
            nativeState: "",
            education: "",
          });
          navigate('/thankyu')
          // window.location.reload()


        }

      } catch (error) {
        if (error.response.data.message) {
          setErrorMessage("Cannot register with this mobile number")
        }
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({ ...formData, [name]: type === "checkbox" ? checked : value, });
    if (errors[name]) {
      setErrors({ ...errors, [name]: null, });
    }
  };

  const handleToggle = () => {
    setShowAll(!showAll);
  };

  const [activeTab, setActiveTab] = useState("Tab 1");
  const [activeContent, setActiveContent] = useState(null);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    setActiveContent(null);
  };

  const handleContentClick = (contentId) => {
    setActiveContent(activeContent === contentId ? null : contentId);
  };

  const qualifications = [
    { value: "", label: "Select Highest Qualification" },
    { value: "high_school", label: "High School" },
    { value: "bachelors_degree", label: "Bachelor's Degree" },
    { value: "masters_degree", label: "Master's Degree" },
    { value: "doctorate_degree", label: "Doctorate" },
  ];


  const nativeStates = ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa",
    "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya",
    "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal",
  ];

  const targetFieldRef = useRef(null);

  const handleClick = () => {
    if (targetFieldRef.current) {
      targetFieldRef.current.focus();
    }
  };

  const features = [
    {
      id: 1,
      icon: eligibilityIcon,
      title: "Eligibility",
      description: "Suitable for All Graduates",
      extra: "No Coding Background Required",
    },
    {
      id: 2,
      icon: courseDurationIcon,
      title: "Course Duration",
      description: "6 Months to Complete, Provided the Schedule is Followed",
    },
    {
      id: 3,
      icon: intershipIcon,
      title: "INTERNSHIP PROGRAM",
      description:
        "Work on live projects and gain practical insights into industry practices",
    },
    {
      id: 4,
      icon: certificateIcon,
      title: "CERTIFICATE",
      description: "Certification Ensures You Are Ready for Industry Roles",
    },
  ];

  const keyHighlights = [
    {
      img: livesections,
      title: "Live Sessions Available Monday through Friday",
      text: "To understand coding best practices, receive interview advice, address queries, and more from experts.",
    },
    {
      img: oneOnOne,
      title: "One-on-One Success Coaching for Each Student",
      text: "To assist you and make sure your learning journey is smooth",
    },
    {
      img: plecement,
      title: "Placement Success Coordinator",
      text: "To provide feedback on your interview performance and enhance your confidence.",
    },
    {
      img: softskills,
      title: "Soft Skills Sessions at TechU",
      text: "Our Soft Skills Sessions empower you with essential interpersonal skills",
    },
    {
      img: mockinterviews,
      title: "Mock Interviews",
      text: "Conduct mock interviews that mimic real-life scenarios and get immediate, specific feedback",
    },
    {
      img: Masterclasses,
      title: "Masterclasses with Top Industry Professionals",
      text: "To learn full web development and provide you with the latest trends and keep you aligned with industry developments.",
    },
    {
      img: merntoring,
      title: "Support from Successful Senior Mentors",
      text: "Alumni from over 1,700 companies to perfect your placement preparation.",
    },
  ];

  const reviewsData = [
    {
      degree: "Non-Engineering Degree",
      // companyImg: caprusit,
      reviewText: "I registered in TechU , my first step toward a job, with my husband's help. Here, I started from scratch and developed solid tech foundations. I had no experience with coding when I first started, but today I can easily develop straightforward methods to solve complicated issues. Along with that, I aced my tech interview thanks to the placement help staff.",
      studentImg: anusha,
      studentName: "Anusha",
      designation: "Angular Developer",
      package: "14 Lpa",
      location: "Hyderabad",
    },
    {
      degree: "Non-Engineering Degree",
      // companyImg: finshots,
      reviewText: "I moved from a tiny support function to the IT industry. I enrolled in TechU to get tech skills because I have always been interested in software. I improved my skill set and honed my programming abilities here. I was able to land a well-paying position with the placement support team's unwavering help.",
      studentImg: akhil,
      studentName: "Akhil",
      designation: "Angular Developer",
      package: "16 Lpa",
      location: "Hyderabad",
    },
    {
      degree: "Non-Engineering Degree",
      // companyImg: purple,
      reviewText: "This program provided me with the career I always wanted. Although it took me extra time in college to clear my backlogs, the methodologies and practical learning offered here make it a one-stop solution for learning, practicing, building web applications, and tracking my progress. The placement support helped me overcome my nervousness and perform well in interviews.",
      studentImg: sakshi,
      studentName: "Sakshi",
      designation: "MERN Stack Developer",
      package: "8 Lpa",
      location: "Hyderabad",
    },
    {
      degree: " Non IT Branch to IT Job",
      // companyImg: preludesys,
      reviewText: "After receiving my degree in EEE, I spent a month working in my primary field. The epidemic then struck. It was then that I learned about TechU, which completely changed my life. The straightforward explanations provided by Rahul sir enabled me to grasp the difficult ideas. I received the much-needed motivation from mindset training until I was hired.",
      studentImg: prem,
      studentName: "Prem Nayak",
      designation: "Software Developer",
      package: "15 Lpa",
      location: " Hyderabad",
    },
    {
      degree: " Non IT Branch to IT Job",
      // companyImg: predera,
      reviewText: "I joined TechU  to acquire skills that are ready for the workforce after my career gap prevented me from finding employment. All of the information in TechU is structured. I picked up coding quickly because to this place's trainers and instructional techniques. Real-world tasks helped me hone my abilities and advance to the position of Angular Developer.",
      studentImg: mahendra,
      studentName: " Mahendra",
      designation: "Angular Developer",
      package: "14 Lpa",
      location: " Hyderabad",
    },
    {
      degree: "Got Into Top MNCs",
      // companyImg: tcs,
      reviewText: " At TechU, the team provided me with comprehensive support. Learning in my native language made the process much easier. I felt confident that I would secure a job. If you are determined to learn and advance in your career, TechU is the perfect place for you.",
      studentImg: chaitanya,
      studentName: " Chaitanya",
      designation: "Angular Developer",
      package: "14 Lpa",
      location: "Hyderabad",
    },
  ];

  return (
    <div className="">
      {/* hero section */}
      <div id="hero-section">
        <div className="hero-section-container">
          <div className="hero-main-container">
            <div className="hero-section-sub-container-2">
              <div className="hero-section-left-container section-mobile-margin">
                <div className="hero-section-heading-div">
                  <h1 className="hero-section-h1 width">
                    <span className="hero-section-h1-span-2">
                      Full Stack with Generative AI
                    </span>{" "}
                    Course in Hyderabad with Job Placement Support
                  </h1>
                </div>
                <div className="reviews-and-ratings rating-mobileview-1">
                  <img
                    src={ratingStarts}
                    loading="lazy"
                    alt=""
                    className="ratings-stars"
                  />
                  <div className="rating-points">
                    <strong>4.71/5 |</strong> 2 Lakh+ Ratings
                  </div>
                </div>
                <div className="reviews-and-ratings-c">
                  Join our industry-designed Full Stack Developer course in
                  Hyderabad by enrolling today. Get an Industry-ready credential
                  and become employable with a program created around the skills
                  that the firm needs.
                </div>
                <div className="rationg-pakage-container">
                  <div>
                    <div className="reviews-and-ratings rating-mobileview">
                      <img
                        src={ratingStarts}
                        loading="lazy"
                        alt=""
                        className="ratings-stars"
                      />
                      <div className="rating-points">
                        <strong>4.71/5 |</strong> 2 Lakh+ Ratings
                      </div>
                    </div>
                    <div
                      id="curriculum-download"
                      className="curriculum-div-block"
                      onClick={handleClick}
                      data-tip="Download the curriculum and explore the course content"
                      data-place="top"
                    >
                      <div className="curriculum-text-block">
                        Download Curriculum
                      </div>
                      <div className="curriculum-icon">
                        <MdOutlineFileDownload
                          size={20}
                          style={{ color: "#C76A97" }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div>
                    <img
                      src={highestPakage}
                      alt="no img"
                      className="highest-pakage-img"
                    />
                  </div> */}
                </div>
              </div>
             
              <div className="hero-section-right-container">
                <div id="form" className="form-container">
                  <div className="form-container-sub-1">
                    <div className="text-under-line">
                      <div >
                        Book a Live Demo <br /> For Free
                      </div>
                    </div>
                    <div className="form-student-img w-embed">
                      <img src={formStudent} alt="" loading="eager" className="fromstudent-img" />
                    </div>
                  </div>
                  <div id="book-a-free-demo" className="scholarship-form-techu">
                    <form className="form" onSubmit={handleSubmit}>
                      <fieldset className="fieldset">
                        <div className="form-group mb-1">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Enter Your Name"
                            className="form-control"
                            value={formData.name}
                            onChange={handleChange}
                            ref={targetFieldRef}
                          />
                          {errors.name && (
                            <span className="error">{errors.name}</span>
                          )}
                        </div>
                        <div className="form-group mb-1">
                          <label className="form-label">
                            Mobile Number (WhatsApp Number)
                          </label>
                          <div className="mobile-input-container form-control">
                            <select
                              id="countryCode"
                              name="countryCode"
                              className="selisted-li-list-in me-1"
                              value={formData.countryCode}
                              onChange={handleChange}
                            >
                              <option value="">IN</option>
                            </select>
                            <input
                              type="number"
                              id="mobileNumber"
                              name="mobile"
                              placeholder="Enter Mobile Number"
                              className="mobile-input ms-4"
                              value={formData.mobile}
                              onChange={handleChange}
                            />
                            <span className="mobilenumber-1">+91</span>
                          </div>
                          {errors.mobile && (
                            <span className="error">{errors.mobile}</span>
                          )}
                          <p className="form-check-label">
                            We assure you that we practice a strict 'NO-SPAM'
                            policy
                          </p>
                        </div>
                        <div className="form-group mb-1">
                          <label className="form-label">
                            Highest Qualification
                          </label>
                          <select
                            name="education"
                            className="form-control selectedItem-name"
                            value={formData.education}
                            onChange={handleChange}
                          >
                            {qualifications.map((option, index) => (
                              <option
                                key={index}
                                value={option.value}
                                className="selisted-li-list"
                              >
                                {option.label}
                              </option>
                            ))}
                          </select>
                          {errors.education && (
                            <span className="error">{errors.education}</span>
                          )}
                        </div>
                        <div className="form-group mb-1">
                          <label className="form-label">Location</label>
                          <select
                            name="nativeState"
                            className="form-control"
                            value={formData.nativeState}
                            onChange={handleChange}
                          >
                            <option value="">Select your State</option>
                            {nativeStates.map((state, index) => (
                              <option key={index} value={state}>
                                {state}
                              </option>
                            ))}
                          </select>
                          {errors.nativeState && (
                            <span className="error">{errors.nativeState}</span>
                          )}
                        </div>
                        <div className="form-group mb-1">
                          <div className=" form-check-1">
                            <input
                              type="checkbox"
                              name="receiveUpdates"
                              className="form-check-input"
                              checked={formData.receiveUpdates}
                              onChange={handleChange}
                            />
                            <div className="form-check-label-c">
                              <FaWhatsapp
                                style={{
                                  color: "#0ed10e",
                                  fontSize: "27px",
                                  marginTop: "5px",
                                }}
                              />
                              <label className="form-check-label">
                                I want to receive updates directly on WhatsApp
                              </label>
                            </div>
                          </div>

                          {errors.receiveUpdates && (
                            <span className="error">
                              {errors.receiveUpdates}
                            </span>
                          )}
                        </div>

                        <div className="form-group text-center-content-tfd">
                          <div className="form-techu-hh">
                            <span className="form-spam-hres form-check-label">
                              By proceeding further, I agree to the{" "}
                              <a
                                href="https://www.techu.in/#/terms"
                                className="form-links"
                              >
                                Terms & Conditions
                              </a>{" "}
                              and{" "}
                              <a
                                href="https://www.techu.in/#/privacy"
                                className="form-links"
                              >
                                Privacy Policy
                              </a>{" "}
                              of TechU
                            </span>
                          </div>
                        </div>
                        {errorMessage && <h6 style={{ color: "red" }}>{errorMessage}</h6>}
                        <div className="form-button-demo-t">
                          <button type="submit" className="button-demosection">
                            <div className="book-demo-button-text">Submit</div>
                          </button>
                        </div>
                      </fieldset>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* chat with us container */}
      <div id="chat-with-us-div" className="whatsappdiv-container" onClick={handleWhatsAppClick} style={{ cursor: "pointer" }}>
        <div
          id=""
          href=""
          class="whatsappdiv-block whatup-home  whatsapp-us t-inline-block-whatup"
        >
          <div class="whatup-text">Chat with us</div>
          {/* <div className="phone-icon-div">
            <FiPhone size={40} className="phone-iocn" />
          </div> */}
          <div className="whapup-div">
            <div class="whatup-img">
              <FaWhatsapp
                style={{
                  color: "#ffff",
                  fontSize: "35px",
                  marginTop: "5px",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* featured contaainer */}
      <div>
        {" "}
        <div id="featured-container" className="featured-container">
          <div id="feature-desktop" className="featured-main-container">
            <div className="featured-div-grid program-features-grid">
              {features.map((feature) => (
                <div key={feature.id} className="program-features">
                  <div>
                    <div className="featured-image">
                      <img
                        src={feature.icon}
                        alt=""
                        loading="lazy"
                        className="featured-image-img"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="featured-course-text">{feature.title}</div>
                    <div className="featured-course-description">
                      {feature.description}
                      {feature.extra && (
                        <span className="text-span-"> {feature.extra}</span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
   {/* About Trainer */}
   <section className="about-trainer-main">
        <div className="about-trainer-section">
          <div className="trainer-section-left">
            <h1 className="trainer-head">Know Your Trainer</h1>
            <p className="trainer-para"  >
              Atul Sharma, with 8 years of experience in software development, has trained over 5,000 students. Formerly at TCS and Adjoint, he excels in full-stack development, emphasizing practical, hands-on learning.</p>
            <ul className="trainer-ul-tags">
              <li><strong className="trainer-strong-para">8 years of hands-on experience</strong> in the software industry, making complex topics easy to understand.</li>
              <li><strong className="trainer-strong-para"> Mentored and trained over 5,000 students</strong>, helping them build successful careers in tech.</li>
              <li>Holds an <strong className="trainer-strong-para" > M.Tech in IT</strong>, ensuring deep knowledge and expertise in the field.</li>
              <li><strong className="trainer-strong-para">GATE qualified</strong>, with a passion for breaking down tough concepts into fun, engaging lessons.</li>
            </ul>

          </div>
          <div className="trainer-section-right">
            <img src={atulSharma} className="trainer-img" />
          </div>
        
        </div>
        <div className="buttons-container trainer-btn-div">
            <button
              id=""
              href="#hero-section"
              onClick={() => handleclick("hero-section")}
              className="book-start-a-demo trainer-btn"
            >
              Book a Free Demo
            </button>
          </div>
      </section>
      {/* reviews sectins */}
      <section id="Testimonials" className="reviews-slider">
        <div className="reviews-slider-container">
          <div className="reviews-heading-div">
            <h2 className="reviews-heading">
              Your Seniors Were Placed. Now it's your turn!
            </h2>
          </div>

          <div className="reviews-map-container">
            {reviewsData.map((review, index) => (
              <div className="reviews-slider-card-container reviews-slider-1">
                <div className="reviews-slider-caard">
                  <div className="slider-card-div-heading">
                    <div
                      className={
                        review.degree === "Non-Engineering Degree"
                          ? "slider-card-non-enginer"
                          : review.degree === "Career Gap"
                            ? "slider-card-carrer"
                            : review.degree === "Got Into Top MNCs"
                              ? "slider-card-mnc-container"
                              : "slider-card-it-enginer"
                      }
                    >
                      <div
                        className={
                          review.degree === "Non-Engineering Degree"
                            ? "slider-card-non-enginer-text"
                            : review.degree === "Career Gap"
                              ? "slider-card-carrer-gap-text"
                              : review.degree === "Got Into Top MNCs"
                                ? "slider-card-mnc-text"
                                : "slider-card-non-it-text"
                        }
                      >
                        {review.degree}
                      </div>
                    </div>
                    <div className="slider-reviews-company-img">
                      <img src={review.companyImg} loading="lazy" alt="" />
                    </div>
                  </div>
                  <div className="reviews-slider-card-content">
                    <div className="reviews-slider-text-para">
                      {review.reviewText}
                    </div>
                    <div className="reviews-slider-img-container">
                      <div className="reviews-slider-img w-embed">
                        <img src={review.studentImg} loading="lazy" alt="" className="reviews-images-img" />
                      </div>
                      <div className="reviews-slider-img-content">
                        <div className="reviews-slider-card-student-name">
                          {review.studentName}
                        </div>
                        <div className="reviews-slider-card-designation">
                          {review.designation}
                        </div>
                        <div className="reviews-slider-card-pakage">
                          {review.package}
                        </div>
                        <div className="reviews-slider-card-native-place">
                          {review.location}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="book-demo-container">
          <div className="">
            <div className="">
              <div className="">
                <div className="book-demo-reviews-buttons batch-dates-buttons">
                  <div className="buttons-container">
                    <button
                      id=""
                      // href="#hero-section"
                      onClick={() => handleclick("hero-section")}
                      className="book-start-a-demo "
                    >
                      Book a Free Demo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
      {/* course overview */}
      <div>
        <section id="overview" className="course-overview-container">
          <div className="course-overview-main-container">
            <div className="course-overview-div-block">
              <h3 className="course-overview-heading">
                Explore the Full Stack with Generative AI Course in Hyderabad.
              </h3>
            </div>
            <div className="course-overview-text-block">
              You may become a high-paying software professional and learn the
              fundamentals of contemporary web programming with our Full Stack
              Developer course in Hyderabad. You can develop skills relevant to
              the industry with this training. You'll soon be well on your way
              to a prosperous career with committed placement help.
            </div>
            <div className="course-overview-key-heighlights">
              <div className="key-heighlights-text-block">Key Highlights</div>
              <div className="key-heighlights-div-block-1">
                {keyHighlights.slice(0, 4).map((highlight, index) => (
                  <div key={index} className="key-heighlights-div-block-2">
                    <img
                      loading="lazy"
                      src={highlight.img}
                      alt=""
                      className="key-heighlights-image"
                    />
                    <div className="div-block-content-main">
                      <div className="text-block-text">{highlight.title}</div>
                      <div className="text-block-text-1">{highlight.text}</div>
                    </div>
                  </div>
                ))}
                {showAll &&
                  keyHighlights.slice(4).map((highlight, index) => (
                    <div key={index} className="key-heighlights-div-block-2">
                      <img
                        loading="lazy"
                        src={highlight.img}
                        alt=""
                        className="key-heighlights-image"
                      />
                      <div className="div-block-content-main">
                        <div className="text-block-text">{highlight.title}</div>
                        <div className="text-block-text-1">
                          {highlight.text}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div
                className="course-show-hide-container"
                onClick={handleToggle}
              >
                <div className="show-allcourse-updated">
                  {showAll ? "Hide all Upgrades" : "Show all Upgrades"}
                </div>
                <div className={`transform-example ${showAll ? "active" : ""}`}>
                  <FaChevronDown
                    size={20}
                    className="show-more-icon ms-2"
                    style={{ color: "#C76A97" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* course curriculum */}
      <section id="syllabus" className="curriculum-main-container">
        <div className="curriculum-container">
          <article>
            <h2 className="curriculum-heading">
              Full Stack Developer Course Syllabus
            </h2>
            <p className="fullstack-curriculum-content">
              Our course in Full Stack Web Development you will learn complete web development course with react js angular and nodejs consists of modules that
              encompass the following Topics.
            </p>
            <div className="curriculum-tabs">
              <div className="curriculum-tabs-menu t-tab-menu">
                <button
                  className={`curriculum-tab-1 track-button t-inline-block t-tab-link ${activeTab === "Tab 1" ? "t--current" : ""
                    }`}
                  onClick={() => handleTabChange("Tab 1")}
                >
                  <div>Fundamentals</div>
                </button>
                <button
                  className={`curriculum-tab-2 track-button t-inline-block t-tab-link ${activeTab === "Tab 2" ? "t--current" : ""
                    }`}
                  onClick={() => handleTabChange("Tab 2")}
                >
                  <div>Full Stack With Generative AI</div>
                </button>
              </div>
              <div className="curriculum-tabs-content-2">
                {activeTab === "Tab 1" && (
                  <div className="t-tab-pane t--tab-active">
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content1-1")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Build Your Own Static Website
                        </h4>
                        {activeContent === "content1-1" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content1-1" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <p className="fundamentals-main-paragraphs">
                              This course provides a robust foundation in web
                              technologies such as HTML and CSS, enabling you to
                              create and publish simple and attractive websites
                              from scratch.
                            </p>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Software Development Fundamentals
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="accordion-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Importance of Frontend</li>
                                          <li>Fundamentals</li>
                                        </ul>
                                        <ul className="body-square-unorder-list">
                                          <li>Syntax</li>
                                          <li>Debugging</li>
                                          <li>Tweaking Code</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    HTML Basics
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Getting Started</li>
                                          <li>HTML Elements</li>
                                          <li>HTML Headings</li>
                                          <li>HTML Paragraphs</li>
                                          <li>HTML Button</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>HTML Attributes</li>
                                          <li>HTML Images</li>
                                          <li>HTML Hyperlinks</li>
                                          <li>HTML Containers</li>
                                          <li>HTML Lists</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    CSS Basics
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>CSS Rulesets</li>
                                          <li>CSS Properties</li>
                                          <li>CSS Colors</li>
                                          <li>CSS Units</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>CSS Box Model</li>
                                          <li>CSS Margins</li>
                                          <li>CSS Paddings</li>
                                          <li>CSS Borders</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Bootstrap Components
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Carousel</li>
                                          <li>Embedding Videos</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Course Project
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <p className="fundamentals-project-heading">
                                          Tourism Website
                                        </p>
                                        <p className="fundamentals-main-paragraphs">
                                          Build your Tourism Website by using
                                          HTML5, CSS3 and Bootstrap
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content1-2")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Build Your Own Responsive Website
                        </h4>
                        {activeContent === "content1-2" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content1-2" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <p className="fundamentals-main-paragraphs">
                              This course provides a solid foundation in web
                              technologies including HTML, CSS, and Bootstrap.
                              By mastering these skills, you'll be able to
                              develop a responsive web application that performs
                              seamlessly on various devices, such as desktops,
                              tablets, and mobile phones.
                            </p>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Intermediate CSS
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="accordion-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Block vs inline elements</li>
                                          <li>CSS Selectors</li>
                                        </ul>
                                        <div className="accordion-fundamentals-children-1">
                                          <ul className="body-square-unorder-list">
                                            <li>className Selector</li>
                                            <li>CSS Specificity</li>
                                            <li>Id Selector</li>
                                          </ul>
                                          <ul className="body-square-unorder-list">
                                            <li>CSS Inheritance</li>
                                            <li>Type Selector</li>
                                            <li>CSS Cascading</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    Course Project
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <p className="fundamentals-project-heading">
                                          Food Munch
                                        </p>
                                        <p className="fundamentals-main-paragraphs">
                                          Build a responsive Food Munch website
                                          using HTML5, CSS3 and Bootstrap Grid
                                          System.
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    Bootstrap Grid System
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Container</li>
                                          <li>Row</li>
                                          <li>Column</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Bootstrap Utilities
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Display Utilities</li>
                                          <li>Flex Utilities</li>
                                          <li>Sizing Utilities</li>
                                          <li>Spacing Utilities</li>
                                          <li>Colors</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Bootstrap Components
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Navbar</li>
                                          <li>Buttons</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSix"
                                  >
                                    Course Project
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSix"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <p className="fundamentals-project-heading">
                                          Ecommerce Website
                                        </p>
                                        <p className="fundamentals-main-paragraphs">
                                          Build a responsive Ecommerce
                                          websiteusing HTML5, CSS3 and Bootstrap
                                          Grid System
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content1-3")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Programming Foundations with JavaScript
                        </h4>
                        {activeContent === "content1-3" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content1-3" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <p className="fundamentals-main-paragraphs">
                              You will learn the fundamental concepts of
                              programming and gain the confidence to code in
                              JavaScript
                            </p>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Fundamentals of Programming
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Operators, Datatypes &amp; Variables
                                          </li>
                                          <li>Strings &amp; Numbers</li>
                                          <li>Conditional Statements</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Loops</li>
                                          <li>String Methods</li>
                                          <li>Functions</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main-end">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content1-4")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Introduction to Databases
                        </h4>
                        {activeContent === "content1-4" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content1-4" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <p className="fundamentals-main-paragraphs">
                              This course will help you build a solid
                              understanding of Database and DBMS concepts. Most
                              importantly, you will be able to execute powerful
                              queries on databases using both SQL and MongoDB.
                            </p>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Relational Databases
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Introduction</li>
                                          <li>SQL / MongoDB</li>
                                          <li>
                                            Aggregation, Relationships,Join
                                          </li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Querying</li>
                                          <li>ACID Properties</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeTab === "Tab 2" && (
                  <div className="w-tab-pane">
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-1")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Dynamic Web Applications
                        </h4>
                        {activeContent === "content2-1" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-1" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Introduction to JavaScript
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Data Types</li>
                                          <li>Variables</li>
                                          <li>Basic Operators</li>
                                          <li>Conditionals</li>
                                          <li>Loops</li>
                                          <li>Functions</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Arrays</li>
                                          <li>Objects</li>
                                          <li>Control Flow</li>
                                          <li>Comments</li>
                                          <li>Math Function</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    DOM Manipulation
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>The JavaScript Console</li>
                                          <li>Introduction to the DOM</li>
                                          <li>Defining the DOM</li>
                                          <li>Select and Manipulate</li>
                                          <li>Important Selector Methods</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Manipulating Style</li>
                                          <li>Manipulating Text and Content</li>
                                          <li>Manipulating Attributes</li>
                                          <li>Local Storage</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    Course Project
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <p className="fundamentals-project-heading">
                                          Todos Application
                                        </p>
                                        <p className="fundamentals-main-paragraphs">
                                          Build a Todos Application using HTML5,
                                          CSS3 and JS
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Forms
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>The form element</li>
                                          <li>The label element</li>
                                          <li>The input element</li>
                                          <li>The option element</li>
                                          <li>The optgroup element</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>The button element</li>
                                          <li>The select element</li>
                                          <li>The textarea element</li>
                                          <li>Form validations and Input</li>
                                          <li>Validations</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Array Methods
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Adding &amp; Removing Elements
                                          </li>
                                          <li>Push</li>
                                          <li>Unshift</li>
                                          <li>Splice</li>
                                          <li>Pop</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Shift</li>
                                          <li>Finding Elements</li>
                                          <li>Find</li>
                                          <li>IndexOf</li>
                                          <li>Concat</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSix"
                                  >
                                    Events, Fetch &amp; Callbacks
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSix"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>How to add Event Listeners</li>
                                          <li>Callbacks</li>
                                          <li>Schedulers</li>
                                          <li>SetTimeout</li>
                                          <li>ClearTimeout</li>
                                          <li>SetInterval</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>ClearInterval</li>
                                          <li>
                                            Hypertext Transfer Protocol (HTTP)
                                          </li>
                                          <li>HTTP Requests using Fetch</li>
                                          <li>HTTP Responses</li>
                                          <li>Working with JSON data</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSeven"
                                  >
                                    Course Project
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSeven"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <p className="fundamentals-project-heading">
                                          Wikipedia Search Application
                                        </p>
                                        <p className="fundamentals-main-paragraphs">
                                          Build a Wikipedia Search Application
                                          using HTML5, CSS3, JS
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-2")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Intermediate JavaScript
                        </h4>
                        {activeContent === "content2-2" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-2" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <p className="fundamentals-main-paragraphs">
                              In this course, you will explore concepts such as
                              Events, Scope, Hoisting, the 'this' keyword, ES6
                              classes, Error handling, advanced Object handling,
                              and managing Asynchronous JavaScript.
                            </p>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Rest parameters and spread syntax
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="accordion-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Destructuring Arrays</li>
                                          <li>Destructuring Objects</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    More Objects
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Constructor Functions</li>
                                          <li>Factory Functions</li>
                                          <li>Constructor Method</li>
                                          <li>New keyword</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    This
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>This In Methods</li>
                                          <li>Window Scope</li>
                                          <li>This In Function Declarations</li>
                                          <li>This In Events</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Prototypes &amp; ES6 classNamees
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Built-in Constructor Functions
                                          </li>
                                          <li>Constructor Property</li>
                                          <li>Inheritance</li>
                                          <li>This In classNamees</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Asynchronous JS
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Async/Await</li>
                                          <li>Promises</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-3")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Scope &amp; Hoisting in JavaScript
                        </h4>
                        {activeContent === "content2-3" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-3" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Array Methods
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Map</li>
                                          <li>Filter</li>
                                          <li>Reduce</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    String Manipulations
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Trim &amp; Split Methods</li>
                                          <li>
                                            ToUpperCase &amp; ToLowerCase
                                            Methods
                                          </li>
                                          <li>Includes Method</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-4")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Intermediate Responsive Web Design
                        </h4>
                        {activeContent === "content2-4" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-4" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <p className="fundamentals-main-paragraphs">
                              This course will guide you in creating responsive
                              layouts through the use of CSS Flexbox and CSS
                              Media Queries.
                            </p>
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    CSS Layouting
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="accordion-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>CSS Flexbox</li>
                                        </ul>
                                        <div className="accordion-fundamentals-children-2">
                                          <ul className="body-square-unorder-list">
                                            <li>Display</li>
                                            <li>Ex-direction</li>
                                            <li>Justify-content</li>
                                            <li>Align-items</li>
                                          </ul>
                                          <ul className="body-square-unorder-list">
                                            <li>Ex-wrap</li>
                                            <li>ex- ow</li>
                                            <li>Align-content</li>
                                            <li>Align-self</li>
                                          </ul>
                                          <ul className="body-square-unorder-list">
                                            <li>Ex-grow</li>
                                            <li>Ex-shrink</li>
                                            <li>order</li>
                                          </ul>
                                        </div>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Sizing Elements</li>
                                          <li>Over flow</li>
                                          <li>Box Sizing</li>
                                        </ul>
                                        <div className="accordion-fundamentals-children-2">
                                          <ul className="body-square-unorder-list">
                                            <li>Content Box</li>
                                            <li>Border Box</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    CSS Media Queries
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="accordion-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Media Query</li>
                                          <li>Media Types</li>
                                          <li>Media Features</li>
                                        </ul>
                                        <div className="accordion-fundamentals-children-2">
                                          <ul className="body-square-unorder-list">
                                            <li>Width, min-width, max-width</li>
                                            <li>
                                              Height, min-height, max-height
                                            </li>
                                            <li>Orientation</li>
                                          </ul>
                                        </div>
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Combining Multiple Media Features in
                                            a single Media Query
                                          </li>
                                        </ul>
                                        <div className="accordion-fundamentals-children-2">
                                          <ul className="body-square-unorder-list">
                                            <li>Logical Operators (and)</li>
                                            <li>Logical Operators (not)</li>
                                            <li>Logical Operators (,)</li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-5")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          React JS - Getting started
                        </h4>
                        {activeContent === "content2-5" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-5" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <p className="fundamentals-main-paragraphs">
                                You'll learn to develop stateful web apps using
                                ReactJS in this course. By the end, you'll have
                                the expertise to build a ReactJS application
                                entirely on your own
                              </p>
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Introduction to React JS
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Why React?</li>
                                          <li>
                                            Stateful + Stateless components
                                          </li>
                                          <li>Hello World</li>
                                          <li>Introducing JSX</li>
                                          <li>Rendering Elements</li>
                                          <li>Components and Props</li>
                                          <li>State and Lifecycle</li>
                                        </ul>
                                        <ul className="body-rounded-unorder-list">
                                          <li>Handling Events</li>
                                          <li>Conditional Rendering</li>
                                          <li>Lists and Keys</li>
                                          <li>Forms</li>
                                          <li>Composition vs Inheritance</li>
                                          <li>Thinking In React</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    CSS in JS
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Styled Components</li>
                                          <li>Emotion</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    Routing
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Query parameters</li>
                                          <li>Path parameters</li>
                                          <li>React Route</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Form Validation and React Class Components
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Form Validation in React</li>
                                          <li>
                                            Introduction to React Class
                                            Components
                                          </li>
                                          <li>React Lifecycle Methods</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    React Hooks, Redux, and Project
                                    Implementation
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Advanced React Hooks</li>
                                          <li>Introduction to React-Redux</li>
                                          <li>React-Redux Projec</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSix"
                                  >
                                    API Integration and React Projects
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSix"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>API Integration in React</li>
                                          <li>API-Based React Projec</li>
                                          <li>Search Functionality in React</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSeven"
                                  >
                                    Course Project
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSeven"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="fundamentals-children">
                                        <p className="fundamentals-project-heading">
                                          Ecommerce Application
                                        </p>
                                        <p className="fundamentals-main-paragraphs">
                                          Full Stack web application development
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-6")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Angular JS - Getting started
                        </h4>
                        {activeContent === "content2-6" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-6" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <p className="fundamentals-main-paragraphs">
                                In this course, you will learn how to develop stateful web applications using AngularJS. Upon completion, you will be equipped to construct a full stack AngularJS application entirely from scratch.
                              </p>
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Introduction to Angular
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Why Angular?</li>
                                          <li>
                                            Understanding Anular ArchitecturL
                                          </li>
                                          <li>Setting Up Anular nvironmen</li>
                                          <li>Basics of TypeScript</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    Components and Directives
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Creating Components</li>
                                          <li>Working with Directives</li>
                                          <li>
                                            Data Binding and Interpolation
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    Services and Dependency Injection
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Building Angular Services</li>
                                          <li>
                                            Implementing Dependency Injection
                                          </li>
                                          <li>Consuming RESTful APIs</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Routing and Navigation
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Introduction to Angular Routing
                                          </li>
                                          <li>Navigating Between Components</li>
                                          <li>
                                            Implementing Route Guards and Lazy
                                            Loading
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Forms and Reactive Programming
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Template-Driven Forms</li>
                                          <li>Reactive Forms and Validation</li>
                                          <li>
                                            Handling Asynchronous Operations
                                            with Observables
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSix"
                                  >
                                    State Management with NgRx
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSix"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Overview of State Management</li>
                                          <li>
                                            Implementing State Management with
                                            NgRx
                                          </li>
                                          <li>
                                            Handling Complex Application State
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSeven"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSeven"
                                  >
                                    Deployment and Best Practices
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSeven"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Building and Optimizing for
                                            Production
                                          </li>
                                          <li>
                                            Deploying Angular Applications
                                          </li>
                                          <li>
                                            Best Practices for Angular
                                            Development
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-7")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Generative AI Applications
                        </h4>
                        {activeContent === "content2-7" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-7" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <p className="fundamentals-main-paragraphs">
                                Generative Artificial Intelligence involves
                                systems that can generate new content, such as
                                images, text, or even music, based on patterns
                                learned from existing data.
                              </p>
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Understanding Generative AI
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Introduction to Generative AI.
                                          </li>
                                          <li>ChatGPT Overview.</li>
                                          <li>
                                            OpenAI Playground Exploration.
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    Techniques and Tools for AI Exploration
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Promoting Techniques.</li>
                                          <li>Exploring AI Tools.</li>
                                          <li>Practical Applications.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    AI Automation and Content Creation
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Introduction to AI Automation.
                                          </li>
                                          <li>Website Building with AI.</li>
                                          <li>AI Generated Presentations.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Image and Video Generation with Advanced
                                    Models
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Mid-Journey Review.</li>
                                          <li>Image Generation with AI</li>

                                          <li>Exploring Advanced Models.</li>

                                          <li>AI for Video Production.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Practical Applications and Integration
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            AI for Technology and Product
                                            Developmen.
                                          </li>
                                          <li>ChatGPT Plugins.</li>
                                          <li>
                                            Interpreting AI-Generated Code.
                                          </li>
                                          <li>AI in Design - UI & UX.</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-8")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          .NET (Core) Development Course
                        </h4>
                        {activeContent === "content2-8" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-8" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <p className="fundamentals-main-paragraphs">
                                Are you ready to embark on a journey into the
                                dynamic world of web development using the power
                                of .NET technologies? Our comprehensive online
                                course, "Mastering .NET Web Development," is
                                designed to equip you with the skills and
                                knowledge required to build cutting-edge web
                                applications from start to finish.
                              </p>
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Introduction to .NET Core
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Understanding .NET Core Framework
                                          </li>
                                          <li>
                                            Setting Up .NET Core Development
                                            Environment
                                          </li>
                                          <li>
                                            Basics of C# Programming Language
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    Core Concepts of .NET
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Object-Oriented Programming (OOP)
                                            Principles
                                          </li>
                                          <li>
                                            Common Language Runtime (CLR) and
                                            Just-In-Time Compilation
                                          </li>
                                          <li>
                                            Building Blocks: Classes, Objects,
                                            and Methods
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    Building Web Applications with ASP.NET Core
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Introduction to ASP.NET Core</li>
                                          <li>
                                            Creating Web APIs and MVC
                                            Applications
                                          </li>
                                          <li>
                                            Routing, Middleware, and Filters
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Database Integration
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Database Fundamentals</li>
                                          <li>
                                            Entity Framework Core for Data
                                            Access
                                          </li>
                                          <li>Handling Data with LINQ</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Security in .NET Core and Testing and
                                    Debugging
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Authentication and Authorization
                                          </li>
                                          <li>Securing APIs</li>
                                          <li>Best Practices for Security</li>
                                          <li>Unit Testing with xUnit</li>
                                          <li>Debugging Techniques</li>
                                          <li>Performance Optimization</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-9")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          Version Control with Git & GitHub
                        </h4>
                        {activeContent === "content2-9" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-9" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Introduction to Git and GitHub
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Git Basic</li>
                                          <li>Collaboration with GitHub</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="ciurriculum-main-end">
                      <div
                        className="curriculum-main-content"
                        onClick={() => handleContentClick("content2-10")}
                      >
                        <h4 className="curriculum-fundamentals-main-headings">
                          DevOps with Azure Course
                        </h4>
                        {activeContent === "content2-10" ? (
                          <FiMinus className="ms-auto" />
                        ) : (
                          <FaPlus className="ms-auto" />
                        )}
                      </div>
                      {activeContent === "content2-10" && (
                        <div className="div-block-curriculum-child">
                          <div className="curriculum-child">
                            <div
                              className="accordion accordion-flush"
                              id="accordionFlushExample"
                            >
                              <p className="fundamentals-main-paragraphs">
                                DevOps practices have revolutionized software
                                development and operations by fostering
                                collaboration, automation, and continuous
                                improvement. Organizations adopting DevOps can
                                achieve faster delivery, higher quality
                                software, and greater responsiveness to customer
                                needs, ultimately gaining a competitive edge in
                                the market.
                              </p>
                              <div className="accordion-item">
                                <h2 className="accordion-header ">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseOne"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseOne"
                                  >
                                    Introduction to DevOps
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseOne"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children ">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Overview of DevOps Principles</li>
                                          <li>Benefits and Challenges</li>
                                          <li>
                                            DevOps in the Software Development
                                            Lifecycle
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseTwo"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseTwo"
                                  >
                                    Azure DevOps Services
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseTwo"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Understanding Azure DevOps</li>
                                          <li>
                                            Version Control with Azure Repos
                                          </li>
                                          <li>
                                            CI/CD Pipelines with Azure Pipelines
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseThree"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseThree"
                                  >
                                    Services and Dependency Injection
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseThree"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Basics of Infrastructure as Code
                                          </li>
                                          <li>
                                            Using Azure Resource Manager (ARM)
                                            Templates
                                          </li>
                                          <li>
                                            Automation with PowerShell and Azure
                                            CLI
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFour"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFour"
                                  >
                                    Continuous Integration (CI) and Continuous
                                    Deployment (CD)
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFour"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>Implementing CI/CD Pipelines</li>
                                          <li>Automated Testing Strategies</li>
                                          <li>
                                            Release Management in Azure DevOps
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseFive"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseFive"
                                  >
                                    Monitoring and Logging in Azure
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseFive"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Overview of Azure Monitor and Azure
                                            Log Analytics
                                          </li>
                                          <li>
                                            Application Insights for Performance
                                            Monitoring
                                          </li>
                                          <li>
                                            Setting Up Alerts and Notifications
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                              <div className="accordion-item mt-4">
                                <h2 className="accordion-header">
                                  <button
                                    className="accordion-button collapsed text-content-heading"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#flush-collapseSix"
                                    aria-expanded="false"
                                    aria-controls="flush-collapseSix"
                                  >
                                    Security in DevOps
                                  </button>
                                </h2>
                                <div
                                  id="flush-collapseSix"
                                  className="accordion-collapse collapse"
                                  data-bs-parent="#accordionFlushExample"
                                >
                                  <div className="accordion-body">
                                    <div data-role="accordion-body-child">
                                      <div className="body-fundamentals-children">
                                        <ul className="body-rounded-unorder-list">
                                          <li>
                                            Implementing Security Best Practices
                                          </li>
                                          <li>
                                            Role-Based Access Control (RBAC) in
                                            Azure
                                          </li>
                                          <li>
                                            Compliance and Governance in DevOps
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="">
              <div role="list" className="">
                <div role="listitem" className="">
                  <div className=" demo-button-collection-container">
                    {/* <div className="demo-button-div-block">
                      <div className="start-batch-icon"></div>
                      <div className="batch-start-text">Batch starts today</div>
                    </div> */}
                    <div className="bact-start-button-container">
                      <div className="">
                        <div className="bact-start-button-container">
                          <button
                            id="book-a-free-demo-course-curriculum"
                            // href="#hero-section"
                            onClick={() => handleclick("hero-section")}
                            className="book-start-a-demo"
                          >
                            Book a Free Demo
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>
    </div>
  );
};

export default TechuHome;
