import "./techucompany.css";
import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "swiper/css";
import "swiper/css/navigation";
import { MdOutlineDone } from "react-icons/md";
import html from "../images/html.png";
import skills from "../images/skill-image.png";
import certificate from "../images/course-certificate.png";
import csslogo from "../images/css.png";
import Angular from "../images/angular.png";
import sql from "../images/sql.png";
import MySql from "../images/mysql.png";
import react from "../images/react.png";
import git from "../images/git.svg";
import MongoDB from "../images/mongodb.png";
import javascript from "../images/javascript.svg";
import industrycertificate from "../images/industry-certificate.svg";
import shareable from "../images/shareable-certificate.svg";
import compete from "../images/search-certificate.svg";
import vscode from "../images/vscode.png";
import jobskill from "../images/Job-sec-image.png";
import companyimageone from "../images/company-image1.png";
import companyimagetwo from "../images/company-image2.png";
import companyimagethree from "../images/company-image3.png";

function Company({ handleclick }) {
  const swiperRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(1);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isMobile = screenWidth <= 767;
  const ipad = screenWidth >= 768 && screenWidth <= 1219;

  // Initialize Swiper
  useEffect(() => {
    const swiper = swiperRef.current?.swiper;
    const handleSlideChange = () => {
      if (swiper) {
        const newIndex = swiper.realIndex; // Use realIndex to account for looping
        setActiveIndex(newIndex);
      }
    };

    if (swiper) {
      swiper.on("slideChange", handleSlideChange);
      // Initialize active index
      handleSlideChange();
    }

    return () => {
      if (swiper) {
        swiper.off("slideChange", handleSlideChange);
      }
    };
  }, [swiperRef]);

  // Handle slide navigation
  const goToSlide = (index) => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideTo(index);
    }
  };

  const slidePrev = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slidePrev();
    }
  };

  const slideNext = () => {
    const swiper = swiperRef.current?.swiper;
    if (swiper) {
      swiper.slideNext();
    }
  };

  const renderPaginationBars = () => {
    const swiper = swiperRef.current?.swiper;
    if (!swiper) return null;

    // Determine slidesPerGroup based on device type
    const slidesPerGroup = isMobile ? 1 : ipad ? 2 : 3;
    const totalSlides = swiper.slides.length;
    const numGroups = Math.ceil(totalSlides / slidesPerGroup);
    const currentGroupIndex = Math.floor(activeIndex / slidesPerGroup);

    return Array.from({ length: numGroups }, (_, index) => (
      <div
        key={index}
        onClick={() => goToSlide(index * slidesPerGroup)}
        className={`progressbar-nav-item ${
          index === currentGroupIndex ? "active" : ""
        }`}
      />
    ));
  };
  const projects = [
    {
      title: "Project 1",
      heading: "Personal Portfolio Website",
      description:
        "Create a personal portfolio to showcase your skills and projects using HTML5, CSS3, and JavaScript. Integrate a back-end for managing contact forms or blog posts.",
    },
    {
      title: "Project 2",
      heading: "Real-Time Chat Application",
      description:
        "Develop a chat application with real-time messaging using Node.js, WebSockets, HTML5, CSS3, and JavaScript. Include user authentication and chat rooms.",
    },
    {
      title: "Project 3",
      heading: "Task Management System",
      description:
        "Build a task management app with React, Express.js, and MongoDB. Implement CRUD operations and user authentication.",
    },
    {
      title: "Project 4",
      heading: "API Integration Dashboard",
      description:
        "Create a dashboard that integrates with various APIs using React and Express.js. Display data from APIs like weather or finance.",
    },
    {
      title: "Project 5",
      heading: "E-commerce Website",
      description:
        "Develop a full-featured e-commerce site using a full stack web app approach with React, Node.js, Express.js, and a database. The site will include product listings, a shopping cart, and payment integration.",
    },
    {
      title: "Project 6",
      heading: "Social Networking Application",
      description:
        "Build a social networking app with features like profiles, posts, comments, and messaging using React, Node.js, Express.js, and a database.",
    },
  ];

  return (
    <>
      <section id="Hiring-companies-section" class="hiring-companies-sec">
        <div class="companies-hired">
          <h2 class="companies-hired-top-div">
            Top IT Companies Hire{" "}
            <span class="companies-hired-top-div-txt-span-01">TechU</span>{" "}
            Learners!
          </h2>
          <div class="companies-hired-images">
            <div className="scroll-container">
              <div className="scroll-shadow"></div>
              <div className="scroll-left ">
                <div className="scroll-images">
                  <img
                    loading="lazy"
                    src={companyimageone}
                    alt="Company Image One"
                  />
                </div>
              </div>
              <div className="scroll-right w-100 my-4">
                <div className="scroll-images">
                  <img
                    loading="lazy"
                    src={companyimagetwo}
                    alt="Company Image One"
                  />
                </div>
              </div>
              <div className="scroll-left w-100 my-4">
                <div className="scroll-images">
                  <img
                    loading="lazy"
                    src={companyimagethree}
                    alt="Company Image One"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="skills-learn-section-container">
        <div className="skills-Learn-section">
          <div className="skills-Learn-container">
            <div className="skills-Learn-inner">
              <div className="skills-Learn-inner-left">
                <div className="skills-Learn-inner-left-01">
                  <h2 className="skills-Learn-inner-left-01-text">
                    Key Skills You’ll Learn Here
                  </h2>
                  <div className="skills-Learn-inner-left-01-info">
                    The Full Stack training program in Hyderabad offers an
                    extensive range of skills:
                  </div>
                  <div className="skills-Learn-inner-left-skills">
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={html}
                          alt="logo"
                        />
                      </div>
                      <div>HTML</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={csslogo}
                          alt="logo"
                        />
                      </div>
                      <div>CSS</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={javascript}
                          alt="logo"
                        />
                      </div>
                      <div>JavaScript</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={react}
                          alt="logo"
                        />
                      </div>
                      <div>React JS</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={Angular}
                          alt="logo"
                        />
                      </div>
                      <div>Angular</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={sql}
                          alt="logo"
                        />
                      </div>
                      <div>SQL</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={MySql}
                          alt="logo"
                        />
                      </div>
                      <div>
                        <strong>MySQL</strong>
                      </div>
                    </div>

                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={MongoDB}
                          alt="logo"
                        />
                      </div>
                      <div className="text-block-9412881956">MongoDB</div>
                    </div>
                  </div>
                </div>
                <div className="skills-Learn-inner-left-01">
                  <h2 className="skills-Learn-inner-left-01-text">
                    Tools Covered
                  </h2>
                  <div className="skills-Learn-inner-left-01-info">
                    By the end of the course, you will be proficient in popular
                    tools and technologies.
                  </div>
                  <div className="skills-Learn-inner-left-skills">
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={git}
                          alt="logo"
                        />
                      </div>
                      <div>Git</div>
                    </div>
                    <div className="skills-Learn-inner-left-skills-individual">
                      <div className="skills-individual-image-div">
                        <img
                          className="skills-individual-image"
                          loading="lazy"
                          src={vscode}
                          alt="logo"
                        />
                      </div>
                      <div>Vs code</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="skills-Learn-inner-left-right">
                <img
                  className="skills-Learn-inner-left-right-image"
                  loading="lazy"
                  src={skills}
                  alt="Skills Illustration"
                />
              </div>
            </div>
          </div>
          <div class="batch-start-container-main">
            <div class="Book-free-demo-main">
              <div class="Book-free-demo-main-section">
                <div class="Book-free-demo-main">
                  <button
                    id="Book-free-demo-main-id"
                    onClick={() => handleclick("hero-section")}
                    class="Book-free-demo"
                  >
                    Book a Free Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="project-course">
        <article className="projects-course-top">
          <div>
            <div className="projects-course-top-heading">
              Projects You'll Develop in the Full Stack Course
            </div>
            <div className="projects-course-top-para">
              Create a Strong Portfolio by Developing Real-World Projects.
            </div>
          </div>

          <div className="swiper-wrapper-container">
            <Swiper
              ref={swiperRef}
              modules={[Navigation]}
              slidesPerView={isMobile ? 1 : ipad ? 2 : 3}
              spaceBetween={10}
              slidesPerGroup={isMobile ? 1 : ipad ? 2 : 3}
              onSlideChange={() => {
                const swiper = swiperRef.current?.swiper;
                if (swiper) {
                  const newIndex = swiper.realIndex;
                  setActiveIndex(newIndex);
                }
              }}
              navigation={{
                nextEl: ".right-nav-swiper-button",
                prevEl: ".left-nav-swiper-button",
              }}
              className="swiper-container"
              loop={true}
            >
              {projects.map((project, index) => (
                <SwiperSlide key={index}>
                  <div className="project-course-project-01">
                    <div
                      className={`project-course-project-0${index + 1}-title`}
                    >
                      {project.title}
                    </div>
                    <div className="project-course-project-01-heading">
                      {project.heading}
                    </div>
                    <div className="project-course-project-01-para">
                      {project.description}
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className="swiper-nav-btns d-flex justify-content-center">
              <button className="left-nav-swiper-button" onClick={slidePrev}>
                <ChevronLeft size={25} />
              </button>
              <div className="custom-pagination">{renderPaginationBars()}</div>
              <button className="right-nav-swiper-button" onClick={slideNext}>
                <ChevronRight size={25} />
              </button>
            </div>
          </div>
        </article>
      </section>
      <div className="job-opportunities-container">
      <div className="job-opportunities-main">
        <div class="job-opportunities-section">
          <div class="job-oppurtunities-section-main">
            <div class="job-opportunities-left">
              <div class="job-opportunities-left-main-text">
                Unlock Your Job Opportunities
              </div>
              <div class="job-opportunities-left-info">
                Achieve career success with our placement assistance program!
                TechU graduates have secured positions at top IT companies with
                impressive salary packages.
              </div>
              <div class="job-opportunities-job-titles">
                <div class="job-opportunities-job-titles-main">
                  <div class="job-opportunities-job-titles-main-01">
                    <div class="job-opportunities-job-titles-left-tick-bg">
                      <div class="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div class="job-opportunities-job-titles-right">
                      <div class="job-opportunities-job-title-name">
                        Full Stack Web Developer 
                      </div>
                    </div>
                  </div>
                  <div class="job-opportunities-job-titles-main-01">
                    <div class="job-opportunities-job-titles-left-tick-bg">
                      <div class="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div class="job-opportunities-job-titles-right">
                      <div class="job-opportunities-job-title-name">
                        Angular Developer 
                      </div>
                    </div>
                  </div>

                  <div class="job-opportunities-job-titles-main-01">
                    <div class="job-opportunities-job-titles-left-tick-bg">
                      <div class="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div class="job-opportunities-job-titles-right">
                      <div class="job-opportunities-job-title-name">
                        React Developer
                      </div>
                    </div>
                  </div>
                  <div class="job-opportunities-job-titles-main-01">
                    <div class="job-opportunities-job-titles-left-tick-bg">
                      <div class="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div class="job-opportunities-job-titles-right">
                      <div class="job-opportunities-job-title-name">
                        Back End Developer 
                      </div>
                    </div>
                  </div>
                  <div class="job-opportunities-job-titles-main-01">
                    <div class="job-opportunities-job-titles-left-tick-bg">
                      <div class="job-opportunities-tick">
                        <MdOutlineDone />
                      </div>
                    </div>
                    <div class="job-opportunities-job-titles-right">
                      <div class="job-opportunities-job-title-name">
                        Junior full stack Developer 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="Job-opportunities-right">
              <div class="Job-opportunities-right-image-div">
                <img
                  loading="lazy"
                  class="Job-opportunities-right-image"
                  src={jobskill}
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="batch-start-container-main">
          <div class="Book-free-demo-main">
            <div class="Book-free-demo-main-section">
              <div class="Book-free-demo-main">
                <button
                  id="Book-free-demo-main-id"
                  onClick={() => handleclick("hero-section")}
                  class="Book-free-demo"
                >
                  Book a Free Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

      <section id="certificate" class="certificate-container">
        <div class="certificate-main-container-div">
          <div class="certificate-container-div-inner">
            <div class="certificate-container-div-inner-left">
              <div class="certificate-container-div-inner-left-heading-div">
                <h2 class="get-certified-heading">Achieve Certification</h2>
              </div>
              <div class="certificate-container-div-inner-left-description">
                Complete your projects and pass the pre-placement assessment to
                earn your Certification, verifying your job readiness.
              </div>
              <div class="certificate-content">
                <div class="certificate-content-container">
                  <div class="certificate-content-image">
                    <img
                      loading="lazy"
                      className="certificate-inner-icon"
                      src={industrycertificate}
                      alt="img"
                    />
                  </div>
                  <div class="certificate-content-div">
                    <div class="certificate-content-txt">
                      Industry recognized & government approved Certification
                    </div>
                    <div>
                      This certification is more than a certificate—it's a mark
                      of your real-world skills and employability, unique in
                      India.
                    </div>
                  </div>
                </div>
                <div class="certificate-content-container">
                  <div class="certificate-content-image">
                    <img
                      loading="lazy"
                      className="certificate-inner-icon"
                      src={shareable}
                      alt="img"
                    />
                  </div>
                  <div class="certificate-content-div">
                    <div class="certificate-content-txt">
                      Shareable, Credible and Official
                    </div>
                    <div>
                      Showcase your Industry recognized & government approved
                      Certification on LinkedIn, Twitter, WhatsApp, or via email
                      to enhance your profile and attract top employers.
                    </div>
                  </div>
                </div>
                <div class="certificate-content-container">
                  <div class="certificate-content-image">
                    <img
                      loading="lazy"
                      className="certificate-inner-icon"
                      src={compete}
                      alt="img"
                    />
                  </div>
                  <div class="certificate-content-div">
                    <div class="certificate-content-txt">
                      Become a Desired Candidate
                    </div>
                    <div>
                      Earn this professional certificate in Full Stack
                      Development to enhance your eligibility for competitive
                      job offers and higher salaries, showcasing your industry
                      readiness.
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="certificate-container-div-inner-right">
              <div class="certificate-container-div-inner-right-image">
                <img
                  loading="lazy"
                  src={certificate}
                  alt="certificate"
                  width="100%"
                />
              </div>
            </div>
          </div>
          <div class="batch-start-container-main">
            <div class="Book-free-demo-main">
              <div class="Book-free-demo-main-section">
                <div class="Book-free-demo-main">
                  <button
                    id="Book-free-demo-main-id"
                    onClick={() => handleclick("hero-section")}
                    class="Book-free-demo"
                  >
                    Book a Free Demo
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Company;
