import React, { useState } from "react";
import Company from "./components/TechuCompany";
import TechuHome from "./components/TechuHome";
import TechuAdvantages from "./components/TechuAdvantages";
import TechuFooter from "./components/TechuFooter";
import { Helmet } from "react-helmet";

const Main = ({ handle }) => {
  const handleclick = (text) => {
    handle(text);
  };
  return (
    <div className="App">
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-VQ9CSP1M6X"
        ></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-VQ9CSP1M6X');
          `}
        </script>
      </Helmet>
      <TechuHome handleclick={handleclick} />
      <Company handleclick={handleclick} />
      <TechuAdvantages handleclick={handleclick} />
      <TechuFooter />
    </div>
  );
};

export default Main;
